export const baseCoordsMobile = {
  x: 390,
  y: 844
}
export const homePathMobile = [
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 172.59005623406142
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 175.0316220143395
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 178.96862151159493
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 181.7990830227097
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 183.9617774023264
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 188.64446788263547
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 191.02766915633214
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 193.13246932187917
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 195.42571207923348
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 197.6467830242139
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 199.51280724041834
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 201.78199272698134
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 203.7785412033587
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 205.322065344354
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 206.8335131242942
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 208.36678730029067
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.535237532395584,
          "y": 210.05062743272828
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.266950607299805,
          "y": 212.29575564849998
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 56.78403560931866,
          "y": 214.52484568374416
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 56.56940753643329,
          "y": 216.17260593938602
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 56.3206305870643,
          "y": 218.3415467682608
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.74503678541917,
          "y": 220.98936596187934
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.66211113562951,
          "y": 222.6290950684751
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.62308751619779,
          "y": 224.23276841583976
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.60845228341909,
          "y": 225.70010516993807
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.59869912954477,
          "y": 227.19954240265616
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.59382071861854,
          "y": 228.28377164144652
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.58894230769231,
          "y": 229.74310136406342
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.584063896766075,
          "y": 231.1863929061528
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.579185485839844,
          "y": 232.6457226287697
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.57430707491361,
          "y": 233.89658012209347
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.57430707491361,
          "y": 235.1514411311579
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.57430707491361,
          "y": 236.39028807725953
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.57430707491361,
          "y": 237.42063867650324
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.57430707491361,
          "y": 238.0438124308654
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.57430707491361,
          "y": 239.04611430235948
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.57430707491361,
          "y": 239.85195449178252
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.57430707491361,
          "y": 240.64576001641873
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.57430707491361,
          "y": 241.60795437781167
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.39382421053373,
          "y": 242.38171820617964
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.21334134615385,
          "y": 243.15548203454765
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.21334134615385,
          "y": 243.92924586291562
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 55.032858481773964,
          "y": 244.51457915825867
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 54.715791115394005,
          "y": 245.2161832330351
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 54.40848057086651,
          "y": 245.88571094675652
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 104.67559374295747,
          "y": 171.07459287959819
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 102.35857596764197,
          "y": 172.38558752050898
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 99.9732677753155,
          "y": 173.76072282474752
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 97.99068157489482,
          "y": 175.1759535803049
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 95.61512286846454,
          "y": 176.3907311968329
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 93.15176743727464,
          "y": 177.7979428621265
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 90.68841200608473,
          "y": 179.2011510116794
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 88.76650883601262,
          "y": 180.07514743895328
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 86.65709128746619,
          "y": 181.410187291873
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83.26204740084134,
          "y": 183.13412287562943
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 80.94015121459961,
          "y": 184.36894218842565
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 79.2377545283391,
          "y": 185.33915564008234
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 77.07683123075044,
          "y": 186.54191065060584
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 75.12350815993089,
          "y": 187.9411032256357
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 72.95770645141602,
          "y": 189.12780799684933
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 71.31872177124023,
          "y": 190.25839119499892
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 69.81143951416016,
          "y": 191.14040671253656
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 68.56268369234525,
          "y": 191.9703041727509
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 67.58221846360426,
          "y": 192.77212878765087
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 66.81638864370493,
          "y": 193.3855349283083
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 66.431030860314,
          "y": 193.8465904127365
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 66.02128102229192,
          "y": 194.2835765675911
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 65.4895855830266,
          "y": 194.84084907640212
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 64.95789014376126,
          "y": 195.41415976574072
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 64.53351240891676,
          "y": 196.02355033187504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 64.60941021259015,
          "y": 196.62893738226867
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 64.24844448383038,
          "y": 197.0939963824376
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 64.02893800001878,
          "y": 197.5630709571296
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 63.82894515991211,
          "y": 198.012091776771
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 63.648458627554085,
          "y": 198.32881569523383
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 63.61431708702674,
          "y": 198.62148234290535
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 63.594803443321815,
          "y": 198.89410729430864
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 63.589925032395584,
          "y": 199.17073576145262
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 63.585046621469345,
          "y": 199.45137980311966
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 63.73138427734375,
          "y": 199.7159856642592
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 64.2386876619779,
          "y": 199.8442790496971
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 64.95086963360126,
          "y": 199.8442790496971
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 65.66304426926833,
          "y": 199.8442790496971
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 65.699342580942,
          "y": 199.97657595087566
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 66.4456660930927,
          "y": 200.1088849108366
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 67.79197546151968,
          "y": 200.309338049866
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 68.47976537851187,
          "y": 200.46970779635896
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 69.3626697246845,
          "y": 200.73831717323918
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 70.32849972064679,
          "y": 201.29558968205023
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 71.59676185021033,
          "y": 202.22972325691114
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 73.3381858238807,
          "y": 203.4685581442304
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 75.1674065223107,
          "y": 204.94392604737484
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 77.05028974092923,
          "y": 206.4714120078426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 78.80849838256836,
          "y": 208.30760279650937
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 80.64747590285081,
          "y": 210.1277554046486
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 82.42791982797476,
          "y": 211.7394357834947
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84.6766471862793,
          "y": 213.61973759800338
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 86.64733446561372,
          "y": 215.43588669040193
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 88.56924497164212,
          "y": 217.02349773967435
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 89.78111560528095,
          "y": 218.6071052732061
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 91.44449234008789,
          "y": 219.97423354596324
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 93.02006501417894,
          "y": 221.0807715321039
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 94.29808396559496,
          "y": 222.1311638276159
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 95.57610291701097,
          "y": 223.18155612312788
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 96.60534491905798,
          "y": 224.18786151036267
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 98.15653287447415,
          "y": 225.73939268283934
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 98.96138998178336,
          "y": 226.53322232504027
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 99.58088948176457,
          "y": 227.19071537397485
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 100.21013846764197,
          "y": 227.98051738287035
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 100.80524591299204,
          "y": 228.76231236028445
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 101.38084705059345,
          "y": 229.41980540921904
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 101.45615797776442,
          "y": 230.04924973040394
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 101.86102940486028,
          "y": 230.65862823775595
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 102.40248166597806,
          "y": 231.27203437841334
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 102.94392659113957,
          "y": 231.88141288576534
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 103.52928455059345,
          "y": 232.65920434743873
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 104.10975676316481,
          "y": 233.44097520728812
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 104.67559374295747,
          "y": 234.07845067751916
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 105.23168123685396,
          "y": 234.70386736539868
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 105.59264696561372,
          "y": 235.15288818504007
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 105.94386320847731,
          "y": 235.59790548894077
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 106.28531529353215,
          "y": 236.03088812805464
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 106.60726107083833,
          "y": 236.29550604797652
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 106.92919951218825,
          "y": 236.5600998503337
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 107.25114528949443,
          "y": 236.82471777025557
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 107.57309106680063,
          "y": 237.08931157261276
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 108.07551604050856,
          "y": 237.5022525154584
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 108.38770499596228,
          "y": 237.75883928633414
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 108.58281942514273,
          "y": 238.0194295729506
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 108.77306277935321,
          "y": 238.1838148939666
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 108.94379248985877,
          "y": 238.33616555019577
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 109.11451486440805,
          "y": 238.47648154163812
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 159.08408531775842,
          "y": 172.85866561094167
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 157.74751809927133,
          "y": 172.85866561094167
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 156.732911330003,
          "y": 172.85866561094167
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 155.02564356877252,
          "y": 172.85866561094167
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 153.58176598182092,
          "y": 173.0190353574346
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 152.50374720646784,
          "y": 173.37985824295694
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.9016119150015,
          "y": 173.87699360418094
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 150.88700514573316,
          "y": 174.462326899524
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 150.04799182598407,
          "y": 175.04766019486703
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 148.83339221660907,
          "y": 175.85350038429007
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 147.71634615384616,
          "y": 176.82772941046983
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 146.72125302828275,
          "y": 177.87010261571803
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 145.75541569636417,
          "y": 179.13299477382859
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 145.05786895751953,
          "y": 180.33573772556974
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 144.02374854454627,
          "y": 181.71889212007207
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 142.9115735567533,
          "y": 183.3626488599732
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 141.9603787935697,
          "y": 184.8740966399134
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 141.0823528583233,
          "y": 186.14499582950538
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 140.418947660006,
          "y": 187.3958533228291
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 140.4265623826247,
          "y": 188.82712225891402
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 139.992435161884,
          "y": 190.05392248144648
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 139.58267798790564,
          "y": 191.06824695894502
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 139.377814072829,
          "y": 192.05850210686995
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 139.3729283259465,
          "y": 193.02471204278592
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 139.3729283259465,
          "y": 194.20741329825884
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 139.3729283259465,
          "y": 195.39011455373176
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 139.3729283259465,
          "y": 196.57280375042234
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 139.3729283259465,
          "y": 198.77383299913453
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 139.3729283259465,
          "y": 200.15298387789613
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 139.3729283259465,
          "y": 201.74462256047397
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 139.53877962552585,
          "y": 203.39237075733348
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 139.89486694335938,
          "y": 204.87977332526475
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 140.29486729548526,
          "y": 206.3591447441499
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 140.724123441256,
          "y": 207.8144709510261
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 141.34361560528095,
          "y": 209.26979715790227
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 141.9826214130108,
          "y": 210.72512336477848
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 142.66554025503305,
          "y": 212.38491828520716
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 143.5484372652494,
          "y": 214.06475490190405
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 144.42159212552585,
          "y": 215.15298765643513
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 145.09962522066556,
          "y": 216.61632089479275
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 145.77278724083533,
          "y": 218.06764358592824
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 146.64592742919922,
          "y": 219.52296979280445
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 147.51419654259314,
          "y": 220.954226670107
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 148.33369621863733,
          "y": 222.14095555888534
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 149.11416860727164,
          "y": 223.10314992027824
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 149.89464099590595,
          "y": 224.06534428167117
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 150.6750987126277,
          "y": 224.6323964629693
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.86044252835788,
          "y": 225.99149358668035
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 152.5823739858774,
          "y": 226.7572503835669
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 153.33357590895432,
          "y": 227.5390212434163
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 154.30428431584284,
          "y": 228.38094130963512
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 155.26036482590894,
          "y": 229.19080913236357
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 156.04083721454327,
          "y": 229.8402951498167
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 156.82618067814752,
          "y": 230.36948275453108
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 157.57249685434195,
          "y": 230.73030564005342
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 158.04293705866885,
          "y": 231.07511446261293
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 159.18925358698917,
          "y": 231.40786450282093
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 160.16971881573016,
          "y": 231.72457636250138
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 161.16481194129355,
          "y": 232.04531585548727
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 162.35989790696362,
          "y": 232.2257393570308
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 163.5598696195162,
          "y": 232.24978456903972
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 165.03301767202524,
          "y": 232.42618043727785
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 166.53053577129657,
          "y": 232.59857278977526
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 167.73050748384915,
          "y": 232.60260042308067
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 168.90120873084433,
          "y": 232.60260042308067
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 169.59115541898288,
          "y": 232.60260042308067
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 170.76185666597806,
          "y": 232.60260042308067
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 171.93255791297324,
          "y": 232.60260042308067
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 173.97641402024487,
          "y": 232.3379825031588
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 175.35199385422925,
          "y": 231.91300689552634
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 176.57147921048679,
          "y": 231.57625333957762
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 177.8055924635667,
          "y": 231.10316319036258
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 179.02994889479416,
          "y": 230.62206600966613
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 180.24943425105167,
          "y": 230.12493064844213
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 181.46404853233923,
          "y": 229.62779528721813
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 181.99788607083835,
          "y": 229.15070162226237
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 183.04176330566406,
          "y": 228.36089961336688
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 184.06612689678485,
          "y": 227.5470523924624
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 184.84659928541916,
          "y": 226.9096010397961
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 185.63682849590595,
          "y": 226.10776436611374
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 186.40752939077524,
          "y": 225.31395884147753
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 186.9928946861854,
          "y": 224.5081186520545
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 187.41239401010367,
          "y": 223.51785144534722
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 187.812379690317,
          "y": 222.52758423863995
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 188.2221221923828,
          "y": 221.72799049847498
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 188.62699361947867,
          "y": 220.54128572726137
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 189.03186504657452,
          "y": 219.35458095604776
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 189.4562501173753,
          "y": 217.95137280649485
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 189.7781958946815,
          "y": 216.9731402645744
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 189.92940462552585,
          "y": 216.09914383730052
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.13428321251502,
          "y": 215.00866814925206
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.32939030573917,
          "y": 213.98632458148975
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.38304548997144,
          "y": 212.955973982246
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.41231595552884,
          "y": 212.09801573549967
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.42207277738132,
          "y": 211.64320670032953
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.42695852426382,
          "y": 210.66497415840908
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.42695852426382,
          "y": 209.68674161648863
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.42695852426382,
          "y": 208.69247089404067
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.42695852426382,
          "y": 207.68618962437057
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.42695852426382,
          "y": 206.67187720565434
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.42695852426382,
          "y": 205.46510662060777
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.42695852426382,
          "y": 204.25032900407982
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.42695852426382,
          "y": 203.22800955388217
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.2318367591271,
          "y": 202.2096815606429
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.03184391902042,
          "y": 201.20739174793115
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 189.83185107891376,
          "y": 200.62430138610551
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 189.4220939049354,
          "y": 199.43758455610953
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 188.99283775916464,
          "y": 198.03438846533896
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 188.56358161339392,
          "y": 196.63118031578605
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 188.11969757080078,
          "y": 194.9794165444035
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 187.24165696364182,
          "y": 192.33737350753134
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 186.6124079777644,
          "y": 191.14890815409439
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 186.13436772273135,
          "y": 189.70160103748194
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 185.51974663367636,
          "y": 188.40264106135797
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 184.73928891695462,
          "y": 187.13574538750672
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 184.08075479360727,
          "y": 185.89691050018743
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 183.47589052640475,
          "y": 185.00287237664534
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 182.72468860332782,
          "y": 184.14891764563973
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 181.963744530311,
          "y": 183.3310548502122
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 181.22228475717398,
          "y": 182.6855844072821
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 180.48083965594952,
          "y": 182.06015566062024
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 179.73939455472507,
          "y": 181.84189170005763
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 178.99307837853064,
          "y": 181.36881360962494
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 178.25163327730618,
          "y": 180.903754609456
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 177.51018817608173,
          "y": 180.44671469955082
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 176.54435084416315,
          "y": 179.9616019443313
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 176.07392531174878,
          "y": 179.4885238538986
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 175.12760162353516,
          "y": 179.0194492792066
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 174.18129260723407,
          "y": 178.55037470451464
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 173.23496891902042,
          "y": 178.08131218860498
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 172.4398686335637,
          "y": 177.67237476150007
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 171.73256507286658,
          "y": 177.4398452614156
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 170.66917419433594,
          "y": 177.01086613804245
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 169.70822260929987,
          "y": 176.45759714497208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 168.71800055870642,
          "y": 175.9805155387987
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 167.56681295541617,
          "y": 175.47536108731094
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 166.3960970365084,
          "y": 174.85393585638977
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 165.16198378342847,
          "y": 174.19644280745518
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 163.95225524902344,
          "y": 173.53493418399756
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 162.9376484797551,
          "y": 172.89747077254887
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 162.41356776310846,
          "y": 172.2560038453595
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 161.4331025343675,
          "y": 171.61855249269314
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 160.64774439885065,
          "y": 171.00114283629503
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 160.05752270038312,
          "y": 170.52806474586234
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 159.6624080951397,
          "y": 170.06700926143412
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 159.28192138671875,
          "y": 169.4696533600866
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 173.89303178470846
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 175.7893597209623
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 177.9703352146239
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 180.00875178332578
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 181.86098075252008
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 183.44460034483416
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 185.93026509217177
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 188.44401474360606
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 190.31002690102818
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 192.53109784600858
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 194.71208539845253
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 197.1536511787306
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 198.92746188629295
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 200.8197863068061
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 202.471562136971
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 204.06320081954883
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 205.90739863969702
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 208.3489644199751
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 210.03859276794145
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 211.71442586889765
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.6286902794471,
          "y": 213.74705422207077
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.79454157902643,
          "y": 215.4509601683413
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.9847922691932,
          "y": 216.97844612880905
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 233.2042987530048,
          "y": 218.50192857353608
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 233.41404841496393,
          "y": 219.50997042542951
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 233.61404125507062,
          "y": 220.75682440301253
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 233.81891984205978,
          "y": 222.06380346940028
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 233.83841881385214,
          "y": 223.29862278219647
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 233.84817563570462,
          "y": 224.5414732440388
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 233.84817563570462,
          "y": 225.77629255683502
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.04329740084134,
          "y": 226.77056327928298
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.23841916597806,
          "y": 227.96527508197804
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.23841916597806,
          "y": 228.5604001085905
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.23841916597806,
          "y": 229.3341639369585
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.23841916597806,
          "y": 230.2963582983514
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.48231036846454,
          "y": 231.53920876019373
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.54085129957932,
          "y": 232.26085453123844
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.70181685227612,
          "y": 232.87826418763655
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.8627970768855,
          "y": 233.50368087551604
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.9018096923828,
          "y": 234.11708701617346
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.92132333608774,
          "y": 234.73049315683085
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.92620908297025,
          "y": 235.2035591884812
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.92620908297025,
          "y": 235.66061115716872
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 235.0627899169922,
          "y": 236.10562846106941
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 235.20912757286658,
          "y": 236.41032977352776
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 235.2286412165715,
          "y": 236.69496527215315
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 235.08717463566705,
          "y": 236.6147984870802
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 245.8674064049354,
          "y": 169.7034610911003
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 247.37469599797174,
          "y": 170.5934956989017
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 249.14539043719952,
          "y": 171.5115910932351
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 250.58438227726862,
          "y": 172.1811188069565
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 253.08676499586838,
          "y": 173.5482350209313
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 254.083985548753,
          "y": 174.28191545676282
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 255.3473839393029,
          "y": 174.95946226074798
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 256.6010108360877,
          "y": 175.63300554899243
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 257.83512408916766,
          "y": 176.17824339301666
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 259.06436626727765,
          "y": 176.7074551152957
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 260.08872985839844,
          "y": 177.32084919717076
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 261.08870873084436,
          "y": 177.9422744280919
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 262.0691739595853,
          "y": 178.44742887957966
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 262.8545320951022,
          "y": 178.92451048575307
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 263.82035475510816,
          "y": 179.54192014215116
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 264.13468580979566,
          "y": 180.16734888881302
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 264.7249075082632,
          "y": 180.63642346350503
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 265.290773831881,
          "y": 181.10148246367396
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 265.84683931790863,
          "y": 181.55451885783842
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 266.3931626539964,
          "y": 182.00755525200287
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 266.754150390625,
          "y": 182.596904121869
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 267.2858311579778,
          "y": 183.18223741721204
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 267.80289870042066,
          "y": 183.60720096606215
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 268.13947237454926,
          "y": 184.0321765736946
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 268.4955596923828,
          "y": 184.61750986903763
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 268.7735924353966,
          "y": 185.07856535346588
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 269.03214087853064,
          "y": 185.50754447683903
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 269.3735856276292,
          "y": 186.07283607591384
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 269.7296729454627,
          "y": 186.55794883113336
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 270.0955317570613,
          "y": 187.1673393972677
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 270.32479506272534,
          "y": 187.89700425857617
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 270.39307814378003,
          "y": 188.5304520955018
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 270.58331416203424,
          "y": 189.3082194396105
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 270.7686791053185,
          "y": 190.08599884250154
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 270.9735576923077,
          "y": 190.90386163792903
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.16866478553186,
          "y": 191.6976792213476
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.1930495042067,
          "y": 192.48748123024313
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.38331486628607,
          "y": 193.46171025642286
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5735508845403,
          "y": 194.4519654043478
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.58816410945013,
          "y": 195.44623612679575
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 196.4485259395075
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 197.4468001776962
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 198.42503271961664
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 199.38723913979192
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 200.34943350118485
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 201.31162786257775
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 202.6827596510756
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 203.4605269951843
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 204.22628379207086
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 204.87576980952403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 205.65353715363273
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 206.43933564678753
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 207.08481814849998
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 207.7102348363795
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 208.3196374612962
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 208.92501245290748
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 209.70277979701615
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.5930645282452,
          "y": 210.49660943921708
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.4223128098708,
          "y": 211.1300572761427
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 271.07599698580225,
          "y": 211.7434392992354
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 270.7150385929988,
          "y": 211.9496685950112
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 270.3443087064303,
          "y": 212.5550677041872
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 269.9589362511268,
          "y": 213.34084207977727
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 269.5833352895883,
          "y": 214.11463002570997
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 269.20773432804987,
          "y": 214.88839385407795
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 268.81261972280646,
          "y": 215.85058821547088
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 268.4175051175631,
          "y": 216.8127825768638
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 267.52486595740686,
          "y": 218.324230356804
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 266.92487276517426,
          "y": 219.26638302192868
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 266.19318448580225,
          "y": 220.21654271853478
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 265.42733999399036,
          "y": 221.18676822897382
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 264.5785698523888,
          "y": 222.37747651592815
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 263.73470012958234,
          "y": 223.1810737718338
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 262.72985018216644,
          "y": 224.23149018491048
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 261.6566878098708,
          "y": 225.47431652918812
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 260.54452749399036,
          "y": 226.73720868729868
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 259.4372382530799,
          "y": 228.00810787689065
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 258.5494701678936,
          "y": 229.23889955638145
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 257.4811788705679,
          "y": 230.30935766572637
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 256.18365361140326,
          "y": 231.4038609870802
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 255.1056201641376,
          "y": 232.4502497668515
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 254.0958991417518,
          "y": 232.88499298818869
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 252.84227224496695,
          "y": 233.75898941546254
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 251.25207167405347,
          "y": 234.86953091734392
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 249.7398963341346,
          "y": 235.75956552514532
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 248.22775033804086,
          "y": 236.64960013294672
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 246.45218482384314,
          "y": 237.56768346849776
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 244.2061791053185,
          "y": 238.82254447756222
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 242.47939770038312,
          "y": 239.68853387335466
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 240.5282240647536,
          "y": 240.45426655267653
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 238.50875267615683,
          "y": 241.08371087386143
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 236.70880244328424,
          "y": 241.65703362198238
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.9137232853816,
          "y": 242.21029055627037
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 233.83841881385214,
          "y": 242.60318980284777
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.52626565786508,
          "y": 242.9640126883701
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 231.2531251173753,
          "y": 243.30879739336493
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 230.39948390080377,
          "y": 243.61750222156397
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 229.78486281174878,
          "y": 243.77785990927455
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 229.20926901010367,
          "y": 243.80593275458892
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.4132882631742,
          "y": 329.6566731240512
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.4132882631742,
          "y": 326.5375484810056
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.4132882631742,
          "y": 323.9097709836553
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 57.642551568838265,
          "y": 320.2173477100535
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 58.32058466397799,
          "y": 316.827841049122
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 59.00837458097018,
          "y": 314.22188994782795
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 59.754698093120865,
          "y": 311.74024077501343
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 60.34004871661846,
          "y": 309.1543313699876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 60.88637938866248,
          "y": 307.24422024550597
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 61.603436103233925,
          "y": 304.9670156686792
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 62.30586125300481,
          "y": 302.9463978627282
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 63.018035888671875,
          "y": 300.9538529020915
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 63.50583296555739,
          "y": 299.2058600475438
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 63.97898747370793,
          "y": 297.8770545850998
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 64.43751408503606,
          "y": 296.1651657249125
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 64.86189181988055,
          "y": 294.9102805982834
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 65.28139847975511,
          "y": 293.68349243453326
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 65.69114098182091,
          "y": 292.4887565142736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 66.08137717613806,
          "y": 291.5225586371399
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 66.4520997267503,
          "y": 290.74479129303126
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 66.82282227736253,
          "y": 289.96699983135784
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 67.41305864774263,
          "y": 288.70813530655266
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 67.76426755464993,
          "y": 288.3856352313435
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 68.13499010526216,
          "y": 287.6158749187162
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 68.52034788865309,
          "y": 286.69776746560046
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 68.90570567204402,
          "y": 285.7596424337812
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 69.33496181781476,
          "y": 284.56490651352146
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 69.76421796358548,
          "y": 283.3501288969935
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 70.17396780160757,
          "y": 282.15942061003915
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 70.59346712552583,
          "y": 280.93663596202975
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 71.02760168222281,
          "y": 279.6697402881785
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 71.45198675302359,
          "y": 278.4349209753823
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 71.87636448786809,
          "y": 277.5912162093755
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 72.31049904456505,
          "y": 276.1719939968597
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 72.7300057044396,
          "y": 274.9371746840635
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 73.1543834392841,
          "y": 273.69032070648046
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 73.57388276320238,
          "y": 272.4755430899525
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 73.98363260122446,
          "y": 271.2728001382114
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 74.37386879554161,
          "y": 270.2785294157634
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 74.5494769169734,
          "y": 269.5007620716547
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 74.74459134615384,
          "y": 268.5345400769564
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 75.07141553438626,
          "y": 267.81916487273446
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 75.21775319026067,
          "y": 267.24984564035424
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 75.38847556480994,
          "y": 266.6645123450112
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 75.56408368624173,
          "y": 265.91481784621686
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 75.74944862952599,
          "y": 265.13702638454345
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 76.07626548180212,
          "y": 264.4915680003957
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 76.27138724693884,
          "y": 263.8621236792108
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 76.47138008704552,
          "y": 263.24871753855336
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 76.65673769437349,
          "y": 262.635311397896
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 76.84210263765775,
          "y": 262.025932890544
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 77.02258917001578,
          "y": 261.41653026562733
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 77.19331154456505,
          "y": 260.9514712654584
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 77.35916284414438,
          "y": 260.64676995300005
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 77.37379807692308,
          "y": 260.20578028240476
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 77.53477096557617,
          "y": 259.7687700099855
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 77.69574385422926,
          "y": 259.8832802071955
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 77.69574385422926,
          "y": 259.61065525579227
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 77.69574385422926,
          "y": 259.3420338201297
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 77.69574385422926,
          "y": 259.0734365020318
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 77.85670940692609,
          "y": 258.8048150663692
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 78.01768229557918,
          "y": 258.5682699917617
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 78.01768229557918,
          "y": 258.8048150663692
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 78.01768229557918,
          "y": 259.52646083741394
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 78.17865518423227,
          "y": 260.3723844193734
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 78.35426330566406,
          "y": 260.9113878729219
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 78.37865536029523,
          "y": 261.88962041484234
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 78.39328325711763,
          "y": 263.1003945156296
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 78.39328325711763,
          "y": 264.29910983406535
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 78.39328325711763,
          "y": 265.5259221153802
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 78.39328325711763,
          "y": 266.7607414281764
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 78.59816184410681,
          "y": 267.9915572252319
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 78.80791150606595,
          "y": 269.43084525158054
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 79.0030259352464,
          "y": 270.629584687581
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 79.40789736234224,
          "y": 271.4171196453944
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 79.81276145348181,
          "y": 272.5998209008673
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 80.43226095346304,
          "y": 273.78252215634024
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 81.28102375910832,
          "y": 275.1817267901525
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 82.98342044536884,
          "y": 277.45893136697924
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84.06632496760442,
          "y": 278.71379237604367
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 85.34434391902043,
          "y": 280.157083918133
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 86.72967323890099,
          "y": 281.43777483899447
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 87.92476654052734,
          "y": 282.96125728372147
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 89.11011035625751,
          "y": 284.6450974161591
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 90.24179165179912,
          "y": 286.1445105313125
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 91.36371612548828,
          "y": 287.63992013072516
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 92.4807621882512,
          "y": 289.1152880338696
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 93.36854494535005,
          "y": 290.38218370772086
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 94.26608452430138,
          "y": 291.42632955397477
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 95.397765819843,
          "y": 292.9257667866928
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 96.53920393723708,
          "y": 294.43321105089234
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 97.4367435161884,
          "y": 295.88050610872244
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 98.2854989858774,
          "y": 297.1113219057779
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 99.12450496967021,
          "y": 298.3301030380466
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 99.9439973097581,
          "y": 299.51680780926023
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 100.75861857487605,
          "y": 300.30436688463834
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 101.60737404456505,
          "y": 301.70357151845053
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 102.42198797372671,
          "y": 302.88624865635876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 103.55366926926833,
          "y": 304.8507448892458
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 104.182925591102,
          "y": 306.1777656519018
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 104.93412751417894,
          "y": 307.37250157216147
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 105.70972149188701,
          "y": 308.5632339766805
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 106.41701771662785,
          "y": 309.99048733824236
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 107.10968604454627,
          "y": 310.90232422453533
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 107.90966474092923,
          "y": 312.1291123882854
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 108.72428600604717,
          "y": 313.3479176381188
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 109.36817022470328,
          "y": 314.39027878458467
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 110.0169328542856,
          "y": 315.43666756435596
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 110.64131076519305,
          "y": 316.463014647859
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 111.24617503239558,
          "y": 317.4572853703069
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 111.8656745323768,
          "y": 318.6600283220481
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 112.31444432185246,
          "y": 319.8948476348443
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 112.92418699998122,
          "y": 320.51804550677116
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 113.52417285625751,
          "y": 321.51631974495984
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 113.91928012554462,
          "y": 322.4905487711396
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 114.29000267615685,
          "y": 323.26431259950755
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 114.85584699190579,
          "y": 324.0380764278755
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 115.4411976154034,
          "y": 325.0002707892685
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 115.81192016601562,
          "y": 325.77403461763646
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 116.3826428926908,
          "y": 326.84046509367596
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 116.71921656681941,
          "y": 327.34162808820537
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 117.0557975769043,
          "y": 327.7946524235875
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 117.38749284010666,
          "y": 328.11539191657334
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 117.59236409113957,
          "y": 328.41606559572625
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 98.01233731783353,
          "y": 315.3279455826746
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 96.55871024498572,
          "y": 315.664723256188
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 94.85143514779898,
          "y": 315.8371156086854
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 93.14416005061223,
          "y": 316.0095079611828
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 91.9176541841947,
          "y": 316.1819003136802
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 90.21037175105168,
          "y": 316.3542926661776
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 88.84943448580228,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 87.27873288668118,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 85.48853213970477,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83.51785219632661,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 81.35204315185547,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 79.2447750384991,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 77.65730344332181,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 75.83295381986177,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 74.0037331214318,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 72.47205880972055,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 71.1891614473783,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 69.94041296151968,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 68.9306772672213,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 68.1404553926908,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 67.85538746760442,
          "y": 316.52668501867504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 67.3090567955604,
          "y": 316.658993978636
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 66.9724831214318,
          "y": 316.7912788210322
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 66.64565893319937,
          "y": 316.7912788210322
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 66.31883474496695,
          "y": 316.7912788210322
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 325.5833611510941
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 322.7769448519883
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 320.2551881690726
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 318.439039076674
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 317.0260633134164
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 314.556424687824
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 312.63203596503814
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 310.7076472422523
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 307.95735251621045
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 306.1234046610611
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 302.7396862156583
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 300.68298853291157
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 298.7207593511066
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 296.4796346510756
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 294.41893345258813
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 292.4023191623778
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 290.8187116288461
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 289.8868089287202
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 288.7842985758849
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 288.0867221344139
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 287.4412396327014
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 286.827833492044
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 286.3667780076158
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 285.91375367223367
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 285.3083786806224
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 284.86736489246243
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 284.4383857690893
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 283.8490248404408
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 283.25968802935705
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 282.4819206852483
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 281.70412922357497
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 281.1227994439726
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 280.60786531999776
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.83058518629807,
          "y": 279.4813097551536
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 151.99156541090744,
          "y": 278.30661553116204
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 152.1769303541917,
          "y": 276.90743501491454
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 152.215942969689,
          "y": 275.6525498882854
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 152.23545661339392,
          "y": 274.594150561292
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 152.24034236027643,
          "y": 273.5597723287429
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 152.24034236027643,
          "y": 272.54145639428594
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 152.24034236027643,
          "y": 271.53114749131043
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 152.24034236027643,
          "y": 270.8536127461076
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 152.24034236027643,
          "y": 270.747591931673
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 152.3915510911208,
          "y": 270.2745259000227
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 152.69886897160455,
          "y": 270.106137063266
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 153.01592900202823,
          "y": 270.08609536699777
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 153.17689455472507,
          "y": 270.2184043269587
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 153.37201631986179,
          "y": 270.8317863500514
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 153.5671380849985,
          "y": 271.7939807114443
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 153.72323256272534,
          "y": 273.4698138124005
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 154.0646773118239,
          "y": 274.36964015147134
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 154.28906954251804,
          "y": 275.5683795874718
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 154.52320392315204,
          "y": 276.803198900268
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 154.8988048846905,
          "y": 278.02198003253665
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 155.27929159311148,
          "y": 279.06836881230794
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 155.69391984205978,
          "y": 280.27511527978976
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 156.27927046555737,
          "y": 281.48186174727164
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 157.03534346360425,
          "y": 282.1170701664206
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 157.82068692720853,
          "y": 283.1314067027015
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 158.84993626521182,
          "y": 284.1737678491674
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 159.89869924692007,
          "y": 285.21615311319795
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 160.96209012545071,
          "y": 286.2545348614878
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 162.25472963773288,
          "y": 287.3330000023141
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 163.62543839674728,
          "y": 288.6560172492294
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 164.9912614088792,
          "y": 289.97100334709853
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 166.27415877122144,
          "y": 291.02542327591595
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 167.26925189678485,
          "y": 291.8432981301258
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 168.26434502234824,
          "y": 292.2660066866762
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 169.25945281982422,
          "y": 293.083881540886
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 170.36185631385214,
          "y": 293.8857182145684
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 171.03501833402194,
          "y": 294.21046122329494
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 171.61061213566705,
          "y": 294.6354127133627
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 172.03499720646784,
          "y": 295.0563848052545
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 172.5569358238807,
          "y": 295.37712429824035
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 173.07398869441104,
          "y": 295.5815930117928
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 173.44958965594952,
          "y": 295.74595421524407
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 173.80080589881308,
          "y": 295.89029783999183
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 174.13250849797174,
          "y": 295.9183465677415
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 174.47883899395282,
          "y": 295.93438474826905
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 174.82029841496393,
          "y": 295.93838826400975
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 175.32759446364182,
          "y": 295.93838826400975
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 175.84466200608472,
          "y": 295.93838826400975
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 176.20074932391827,
          "y": 295.93838826400975
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 176.7324447631836,
          "y": 295.93838826400975
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 177.25926912747897,
          "y": 295.93838826400975
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 177.78607881986179,
          "y": 295.790065241086
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 178.3177742591271,
          "y": 295.349051452926
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 178.84946969839243,
          "y": 294.7597146418422
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 179.38116513765775,
          "y": 294.1703537131938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 179.73725245549127,
          "y": 293.58101690211004
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 180.44942709115836,
          "y": 292.390284497591
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 180.95185206486627,
          "y": 291.75283314492464
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 181.3420955951397,
          "y": 291.13945112183194
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 181.74696702223557,
          "y": 290.76883650504016
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 182.1323248056265,
          "y": 289.9950726766722
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 182.36158811129056,
          "y": 289.34959017495976
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 182.57620884821964,
          "y": 288.7121388222934
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 182.76644486647385,
          "y": 288.0947291658953
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 182.95180980975812,
          "y": 287.4853265409786
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 183.13228900615985,
          "y": 286.8759480336266
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 183.3176539494441,
          "y": 286.26654540870993
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 183.66398444542517,
          "y": 285.6571669013579
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 184.0249575101412,
          "y": 285.03975724495984
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 184.3908016498272,
          "y": 284.43438225334853
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 184.75664578951321,
          "y": 283.8289831441726
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 185.12248992919922,
          "y": 283.2196046368206
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 185.48346299391525,
          "y": 282.6222366766907
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 185.83955031174878,
          "y": 282.03289986560696
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 186.1761239858774,
          "y": 281.6039207422338
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 186.33710421048679,
          "y": 281.1749416188606
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 186.512697660006,
          "y": 280.9807228703069
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 186.78098825307993,
          "y": 280.60786531999776
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 187.03463627741885,
          "y": 280.3953895749639
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 187.22488696758563,
          "y": 280.01052147743263
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 187.40049508901743,
          "y": 279.6135945975498
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 187.70779829758862,
          "y": 279.1806119584359
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 188.03461514986478,
          "y": 278.7436258035813
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 188.35168985220102,
          "y": 278.4429280068637
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 188.70289142315204,
          "y": 278.00594185200913
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 189.05410766601562,
          "y": 277.57293509533054
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 189.40043816199668,
          "y": 277.13194542473525
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 189.76141122671274,
          "y": 276.55461916087364
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.12725536639874,
          "y": 275.95725120074377
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.5028563279372,
          "y": 275.33583802860494
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 190.87357154259314,
          "y": 274.72643540368824
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.06869330772986,
          "y": 273.93663339479275
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.25892932598407,
          "y": 273.1388243544158
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.6150166438176,
          "y": 272.5294217294991
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.99061760535605,
          "y": 271.74765086964976
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 192.36134749192456,
          "y": 270.96985940797634
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 192.71743480975812,
          "y": 270.77566477698736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 193.07352212759164,
          "y": 270.1863279659036
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 193.4101104736328,
          "y": 269.75734884253046
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 193.69302896352914,
          "y": 269.332373234898
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 193.8296097975511,
          "y": 269.0717829482815
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 193.85888026310846,
          "y": 268.80316151261894
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 193.87837923490085,
          "y": 268.5305365612157
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 193.88326498178336,
          "y": 268.25791160981237
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 194.01497474083533,
          "y": 267.98128314266836
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 194.1661834716797,
          "y": 267.6926200107376
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 194.34666266808142,
          "y": 267.2756755521512
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 194.51739971454327,
          "y": 266.85873109356487
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 194.5369133582482,
          "y": 266.56203681258796
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 194.54665550818808,
          "y": 266.28140482970326
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 194.54665550818808,
          "y": 266.5019117237832
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 194.54665550818808,
          "y": 267.1834741022914
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 194.54665550818808,
          "y": 268.1055609535832
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 194.38081888052133,
          "y": 269.37646014317517
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 194.02471689077524,
          "y": 270.77566477698736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 193.61986013559195,
          "y": 272.2109492875953
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 193.1857182429387,
          "y": 273.65824434542543
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 192.7467052753155,
          "y": 274.71442485646617
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 192.3076923076923,
          "y": 276.1577163985555
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 192.05404428335336,
          "y": 277.58096624437667
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.81013840895432,
          "y": 279.03629245125285
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.58576085017276,
          "y": 280.30318812510416
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.5564903846154,
          "y": 281.54201095364107
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.5418478158804,
          "y": 282.9692643152029
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.53697674091046,
          "y": 284.2499552360643
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.5321056659405,
          "y": 285.95383706477014
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.52721991905798,
          "y": 287.64170483051316
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.52721991905798,
          "y": 289.5420242237254
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.52721991905798,
          "y": 291.43436070302084
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.52721991905798,
          "y": 293.3266730647517
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191.52721991905798,
          "y": 294.82384324638764
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 192.07842900202823,
          "y": 298.8971552193447
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 192.5271987915039,
          "y": 300.8456132717042
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 193.00035329965445,
          "y": 303.02658876536583
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 193.48327930157,
          "y": 304.8164255946733
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 193.94667698786807,
          "y": 306.6325746870719
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 194.5954396174504,
          "y": 308.4166474184154
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 195.27347271259015,
          "y": 310.3290255939791
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 195.77589768629807,
          "y": 312.07299081522143
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 196.25882368821362,
          "y": 313.4098033091468
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 196.71735029954175,
          "y": 315.10968162211196
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 197.1466064453125,
          "y": 316.4086536570183
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 197.57097684420071,
          "y": 317.67554933086956
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 198.0099898118239,
          "y": 319.1068062081721
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 198.42950380765475,
          "y": 320.3336184894869
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 198.83436056283804,
          "y": 321.3519344239438
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 199.23436091496393,
          "y": 321.95506648203775
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 199.62947552020734,
          "y": 322.9292955082175
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 200.0197043785682,
          "y": 323.8914898696104
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 200.21482614370493,
          "y": 324.66525369797836
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 200.39043426513672,
          "y": 325.25061111088615
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 200.56602771465595,
          "y": 325.83594440622915
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 200.66846700815054,
          "y": 326.3010034063981
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 200.809933589055,
          "y": 326.6337534466061
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 200.8684598482572,
          "y": 326.9384547590644
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 201.02944007286658,
          "y": 327.3473921861694
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 201.1952913724459,
          "y": 327.77236779380183
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 201.2343039879432,
          "y": 328.0810726220009
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 201.25381763164813,
          "y": 328.369711636367
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 201.39039846567007,
          "y": 328.6463642210757
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 201.53673612154446,
          "y": 328.9229926882197
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 201.5611355121319,
          "y": 329.1996211553637
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 201.57576340895432,
          "y": 329.4802531382484
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 201.7221010648287,
          "y": 329.6326037944776
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 226.72151418832632,
          "y": 312.5415950974017
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 226.72151418832632,
          "y": 309.79130037135985
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 226.96053431584284,
          "y": 306.70425208936933
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 227.68246577336237,
          "y": 304.18473833997103
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 228.45806708702673,
          "y": 300.96940873132496
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 228.96536313570462,
          "y": 298.5198118020008
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 229.42877549391525,
          "y": 297.098804889697
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 229.91168682391827,
          "y": 294.9017912155079
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 230.38972707895132,
          "y": 292.72882275332773
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 230.84825369027945,
          "y": 290.80443403054187
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 231.2531251173753,
          "y": 289.61772925932826
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 231.87261728140024,
          "y": 288.8261666682094
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.4433400080754,
          "y": 286.77747601694404
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 232.75552896352914,
          "y": 285.75515656674645
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 233.1262441781851,
          "y": 284.5844658584956
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 233.53111560528095,
          "y": 283.3817229067545
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 233.94574385422925,
          "y": 282.1549347430044
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.36524317814752,
          "y": 280.9321259774303
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 234.7847425020658,
          "y": 279.7013342979395
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 235.40423466609073,
          "y": 278.68523717943526
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 236.22862008901743,
          "y": 277.2539803021327
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 237.0578619150015,
          "y": 275.99911929306825
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 237.70662454458383,
          "y": 274.75626883122595
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 238.3456303523137,
          "y": 273.5254530341704
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 238.9504946195162,
          "y": 272.50713709971353
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 239.36512286846454,
          "y": 271.5008317124787
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 239.96998713566705,
          "y": 270.49855395854934
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 240.5455809373122,
          "y": 270.0998906140079
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 240.9163108238807,
          "y": 269.3220991523345
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 241.30653968224158,
          "y": 268.3559012752009
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 241.89189030573917,
          "y": 267.38970339806724
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 242.47724092923676,
          "y": 266.4234814033689
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 243.0869909433218,
          "y": 264.9000230762066
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 243.46259190486026,
          "y": 263.96988095830403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 243.84306394136868,
          "y": 263.02772829317934
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 244.24793536846454,
          "y": 261.8891380635483
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 244.8284149169922,
          "y": 260.68639511180714
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 245.40887979360727,
          "y": 259.63600281629516
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 245.82839378943808,
          "y": 259.16515560059753
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 246.2430220383864,
          "y": 258.3152285028973
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 246.63323622483472,
          "y": 257.50135716442816
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 246.9990950364333,
          "y": 256.8639058117618
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 247.36492450420673,
          "y": 256.242492639623
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 247.73565439077524,
          "y": 255.62508298322487
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 248.27223557692307,
          "y": 255.1479893182691
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 248.79904526930588,
          "y": 254.83125334102397
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 249.14539043719952,
          "y": 254.54661784239855
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 249.48683518629807,
          "y": 254.26596174194916
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 250.00877380371094,
          "y": 253.98132624332374
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 250.52584134615384,
          "y": 253.83297910283528
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 250.84777245154746,
          "y": 253.69666662713362
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 251.1697329007662,
          "y": 253.560354151432
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 251.67215787447415,
          "y": 253.560354151432
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 252.0136026235727,
          "y": 253.560354151432
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 252.2282527043269,
          "y": 253.7968992260395
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 252.2623795729417,
          "y": 254.4223400314837
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 252.26725064791165,
          "y": 255.27627064492467
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 252.26725064791165,
          "y": 256.22645445909546
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 252.26725064791165,
          "y": 257.2126940324973
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 252.26725064791165,
          "y": 258.210968270686
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 252.26725064791165,
          "y": 259.2132701421801
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 252.4526155911959,
          "y": 260.2195755294149
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 252.6428516094501,
          "y": 261.2178497676036
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 252.84285912146933,
          "y": 261.80494364516994
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 253.23310265174277,
          "y": 262.7912073361365
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 253.82332435021033,
          "y": 263.77344339379766
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 254.60378206693207,
          "y": 264.73563775519057
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 255.20377525916464,
          "y": 265.9183390106635
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 255.80376845139722,
          "y": 267.1010402661364
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 256.8866729736328,
          "y": 268.3398630946733
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 257.80859727125903,
          "y": 269.3822483587039
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 258.4524888258714,
          "y": 270.30836284330104
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 259.44269620455225,
          "y": 271.47902943398714
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 260.45731764573316,
          "y": 272.2826266898928
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 261.3597282996544,
          "y": 273.65776199413136
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 262.457275390625,
          "y": 275.09705002048
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 263.6084629939153,
          "y": 276.7929248177045
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 264.7645216721755,
          "y": 278.4807684658828
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 265.9254807692308,
          "y": 280.03232375592415
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 267.1156956599309,
          "y": 281.7722854614258
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 268.5107891376202,
          "y": 283.12513613587873
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 269.7253887469952,
          "y": 284.9012018357408
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 270.9253751314603,
          "y": 286.82959407426733
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 272.09117595966046,
          "y": 288.5615487482875
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 272.99361595740686,
          "y": 290.0409442847374
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 274.14967463566705,
          "y": 291.74882962918394
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 275.2813485952524,
          "y": 293.02551703430464
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 276.13011873685394,
          "y": 294.42469755055214
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 276.9788888784555,
          "y": 295.82390218436433
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 277.82762967623194,
          "y": 297.2230827006119
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 278.9934598482572,
          "y": 299.143467907657
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 280.59343191293567,
          "y": 301.5569849601854
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 281.40804584209735,
          "y": 302.4447766344694
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 282.24217341496393,
          "y": 303.79586672669905
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 283.11532827524036,
          "y": 305.2231200882609
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 283.8128603421725,
          "y": 306.4980227935936
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 284.4616229717548,
          "y": 307.5724844186792
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 285.0811298076923,
          "y": 308.6068385336636
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 285.5298849252554,
          "y": 309.61714743663913
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 285.93964209923377,
          "y": 310.45106047137654
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 286.3152430607722,
          "y": 310.7054043087349
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 286.69084402231067,
          "y": 311.3348245123551
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 287.0615739088792,
          "y": 311.94823065301256
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 287.42740337665265,
          "y": 312.5496021288831
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 287.6225398137019,
          "y": 313.1549771204944
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 287.8127758319561,
          "y": 313.7603762296704
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 287.9932403564453,
          "y": 314.36174770554095
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 288.1542205810547,
          "y": 314.79473034465485
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 288.31520080566406,
          "y": 315.2197059522873
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 288.47618103027344,
          "y": 315.6446815599197
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 288.6371319110577,
          "y": 315.9092753622769
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 288.6371319110577,
          "y": 316.32221630512254
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 288.6371319110577,
          "y": 316.5707960445169
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 288.6371319110577,
          "y": 316.82735869782795
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 288.6371319110577,
          "y": 317.0919766177498
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 288.6371319110577,
          "y": 317.1641363713414
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 288.6371319110577,
          "y": 316.85943505888304
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 260.7012235201322,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 259.257331261268,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 257.55007817195013,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 255.84279573880707,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 254.6476804293119,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 253.80653968224158,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 251.991946880634,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 250.32369173490082,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 248.76764737642728,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 247.24082946777344,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 245.50917698786807,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 243.97750267615683,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 242.87997025709885,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 242.26807814378003,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 241.26322819636417,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 240.65347818227914,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 240.2583782489483,
          "y": 291.48951757349676
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 240.05349966195914,
          "y": 291.23293080262096
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 372.1577068166145
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 375.2487586143458
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 376.93259874678336
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 377.6239287389963
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 378.66231048728616
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 379.668615874521
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 380.67089362845036
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 381.66916786663904
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 382.6514280418649
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 383.8341292973378
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 385.2333098135853
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 386.6325144473975
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 387.63655278355026
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 389.82155591051725
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 56.90110646761381,
          "y": 391.1926876990151
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 57.047444123488205,
          "y": 392.4275070118113
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 57.38402146559495,
          "y": 393.67433687182967
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 57.75962242713341,
          "y": 394.8891144883576
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 58.16449018625112,
          "y": 396.1038921048856
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 58.41814554654635,
          "y": 397.48705855817025
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 58.67179723886343,
          "y": 398.7196349374491
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 59.09617497370793,
          "y": 400.41951325041424
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 59.535191609309265,
          "y": 402.1233950791201
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.022985018216644,
          "y": 404.30034293947625
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.301025097186745,
          "y": 406.5254294589798
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.574190433208756,
          "y": 408.7905993710197
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.82784212552584,
          "y": 410.6245472261691
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.837598947378304,
          "y": 412.60908515532435
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.842477358304535,
          "y": 414.3169946173356
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.05222702026367,
          "y": 415.7242062826292
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.26198035020094,
          "y": 417.1234109164414
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.26198035020094,
          "y": 417.9109458742549
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.26198035020094,
          "y": 418.8731402356478
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.40343959514911,
          "y": 420.69329284378705
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.5692872267503,
          "y": 421.71163289580863
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.608314514160156,
          "y": 422.5535770795922
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.783915299635666,
          "y": 423.38746599676483
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.95952342106746,
          "y": 424.20133733523403
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.14488836435171,
          "y": 424.8588303841686
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.325367560753456,
          "y": 425.4882505877888
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.359516437237076,
          "y": 425.96534425274456
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.37415167001577,
          "y": 425.8909174480709
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.51561091496394,
          "y": 426.17960469756645
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.66682698176457,
          "y": 426.45623316471045
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.83267094538762,
          "y": 426.73286163185446
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.99364383404071,
          "y": 427.0054865832577
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.003400655893174,
          "y": 427.27811153466104
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.169251955472504,
          "y": 427.41442401036267
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.330224844125595,
          "y": 427.5387259388422
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.330224844125595,
          "y": 427.78728156067183
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.330224844125595,
          "y": 428.0358371825015
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.330224844125595,
          "y": 428.2643752256276
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.330224844125595,
          "y": 428.46484042343934
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.330224844125595,
          "y": 428.7013372629175
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.330224844125595,
          "y": 428.96595518283937
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.330224844125595,
          "y": 429.24658716572407
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.330224844125595,
          "y": 429.53127089947884
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.17900877732497,
          "y": 429.6755904066619
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 66.76916709313025,
          "y": 375.3489912132516
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 67.33988248384915,
          "y": 376.1548314026747
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 67.89596997774564,
          "y": 376.80431742012786
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 68.46181429349458,
          "y": 377.59411942902335
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 69.05204332791841,
          "y": 378.53627209414805
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 69.63739395141602,
          "y": 379.3461157993118
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 70.24225821861853,
          "y": 380.17600120074377
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 70.6617575425368,
          "y": 380.9818413901668
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 71.0422369150015,
          "y": 381.2041088664701
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 71.76417570847731,
          "y": 381.8135114913868
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 72.31049904456505,
          "y": 382.4108794515167
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 72.65195846557617,
          "y": 382.83583094158445
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 72.97389690692609,
          "y": 383.1004488615063
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 73.31047791701097,
          "y": 383.5254244691388
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 73.84217335627629,
          "y": 383.9624106239934
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 74.55434799194336,
          "y": 384.51166404254064
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 74.88117218017578,
          "y": 384.904563289118
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 75.3543340242826,
          "y": 385.20926460157636
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 75.866515819843,
          "y": 385.6382437249496
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 76.40796808096079,
          "y": 386.0712263640634
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 76.94454193115234,
          "y": 386.39596937279003
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 77.48110844538762,
          "y": 386.8249484961632
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 78.18353359515851,
          "y": 387.2619346510178
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 78.72986426720252,
          "y": 387.5706394792168
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 79.27619493924654,
          "y": 387.8713372759344
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 79.82739668626051,
          "y": 388.308323430789
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 80.383484180157,
          "y": 388.75334073468974
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 80.91517961942232,
          "y": 389.05403853140734
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 81.25663170447716,
          "y": 389.33066699855135
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 81.59809112548828,
          "y": 389.60729546569536
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 81.92978638869066,
          "y": 389.8799204170986
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 82.25173216599684,
          "y": 390.1445142194558
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 82.57367794330303,
          "y": 390.0139899592829
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 82.89561638465294,
          "y": 390.27858376164005
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 83.21756216195914,
          "y": 390.5432016815619
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 83.37853505061223,
          "y": 390.7516739108551
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 83.490723830003,
          "y": 390.8559100255017
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 83.62243358905498,
          "y": 390.99622601694404
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 83.65657512958232,
          "y": 391.2247640600702
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 83.80291278545673,
          "y": 391.37308708299395
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 84.07120337853064,
          "y": 391.5254377392231
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 84.24192575307993,
          "y": 391.769989845312
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 84.42729069636418,
          "y": 392.0305801319285
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 84.74435806274414,
          "y": 392.43951755903345
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 85.08581014779898,
          "y": 392.9887709775807
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 85.44677587655875,
          "y": 393.57010075718307
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 85.79799211942232,
          "y": 394.03115624161126
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 86.1443226154034,
          "y": 394.4801770612527
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 86.4955315223107,
          "y": 394.9251943651534
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 86.83211253239558,
          "y": 395.2178610128249
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 87.00772065382738,
          "y": 395.4985171132743
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 87.17844302837665,
          "y": 395.7711420646776
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 87.49063198383037,
          "y": 395.90745454037926
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 87.7881857065054,
          "y": 395.90745454037926
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 88.07110419640173,
          "y": 395.90745454037926
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 88.20769236637996,
          "y": 395.79518727668653
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 88.34915161132812,
          "y": 395.5746803826065
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 88.50036767812875,
          "y": 395.20182283229735
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 88.66134056678185,
          "y": 394.7888818894517
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 88.9637727003831,
          "y": 394.4882082102988
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 89.29546796358548,
          "y": 394.05922908692565
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 89.65155528141902,
          "y": 393.4859063388047
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 90.01252834613506,
          "y": 392.89656952772094
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 90.37837248582106,
          "y": 392.28716690280424
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 91.24176318828876,
          "y": 391.2247640600702
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 92.12954594538762,
          "y": 389.97390656674645
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 93.0514775789701,
          "y": 388.8976843594375
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 94.14413892305814,
          "y": 387.44235815256127
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 94.9587528522198,
          "y": 386.25565338134766
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 96.07579891498271,
          "y": 384.7963236587307
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 97.15869610126201,
          "y": 383.55347319688843
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 97.75868929349458,
          "y": 382.3667684256748
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 98.66109994741586,
          "y": 380.6268067201732
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 99.21230903038611,
          "y": 379.8490152584998
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 99.7537612915039,
          "y": 379.19952924104666
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 100.17326061542217,
          "y": 378.9492130369937
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 100.56349680973933,
          "y": 378.33180338059555
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 100.94885459313025,
          "y": 377.70235905941064
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 101.33421237652118,
          "y": 376.93259874678336
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 101.85127258300781,
          "y": 376.1748730989429
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 102.40248166597806,
          "y": 375.3730364252606
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 102.82685940082256,
          "y": 374.5551615710507
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 103.21221718421349,
          "y": 373.91771021838434
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 103.74879103440504,
          "y": 373.2922935305049
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 104.2707296518179,
          "y": 372.83123804607663
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 104.6365737915039,
          "y": 372.3741860773891
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 105.18778287447415,
          "y": 371.7688110857778
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 105.73410621056189,
          "y": 371.16343609416657
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 106.09507927527794,
          "y": 370.5700716497774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 106.45116659311148,
          "y": 369.98073483869365
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 106.78774026724008,
          "y": 369.55175571532044
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 107.30480047372671,
          "y": 369.1227765919473
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 107.83649591299204,
          "y": 368.9285578433936
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 108.37794817410982,
          "y": 368.1227176539706
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 108.69989395141602,
          "y": 367.8060057942901
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 109.02671080369215,
          "y": 367.5133391466186
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 109.35841340285081,
          "y": 367.33291564507505
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 109.69011600200946,
          "y": 367.17255795736446
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 110.02181126521184,
          "y": 367.02823845018145
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 110.37302750807542,
          "y": 366.99616208912636
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 110.71447959313025,
          "y": 366.98012390859884
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 111.02666854858398,
          "y": 366.97612039285815
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 111.18276302631084,
          "y": 367.24071419521533
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 111.36324222271259,
          "y": 367.797974645244
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 111.53885034414438,
          "y": 368.3833320581518
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 111.53885034414438,
          "y": 368.96866535349477
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 111.53885034414438,
          "y": 369.5539986488378
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 111.78274888258714,
          "y": 370.4680784686482
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 111.84128247774564,
          "y": 371.0213354029362
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 111.8656745323768,
          "y": 371.3518666271915
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 112.01689059917743,
          "y": 372.13363748704086
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 112.17786348783052,
          "y": 372.7710888397072
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 112.3632210951585,
          "y": 373.52883860511236
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 112.55833552433894,
          "y": 374.4629601211909
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 112.75833570040189,
          "y": 375.43318563162995
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 113.12905825101411,
          "y": 376.43145986981864
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 113.52417285625751,
          "y": 377.6061299762455
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 113.94367218017578,
          "y": 378.812900561292
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 114.17781389676607,
          "y": 380.0677615703565
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 114.40707720243013,
          "y": 381.48700790043694
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 114.8265765263484,
          "y": 382.9142612619988
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 115.25584000807541,
          "y": 383.95440359251194
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 115.69485297569861,
          "y": 385.4097056818234
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 116.12898753239558,
          "y": 386.8489937081721
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 116.55336526724008,
          "y": 388.252201857725
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 116.97775033804086,
          "y": 389.6514064915372
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 117.40212807288536,
          "y": 391.0505870077847
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 117.82650580772986,
          "y": 392.05464946150215
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 118.51917413564829,
          "y": 394.6004513564268
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 118.93868079552283,
          "y": 396.0597810790437
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 119.36305853036734,
          "y": 397.48303092486486
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 119.7971930870643,
          "y": 398.92634658451897
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 120.22644923283504,
          "y": 400.2132839546384
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 120.65083430363582,
          "y": 401.08501333083024
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 120.90936073890099,
          "y": 402.3198326436264
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 121.13374563363882,
          "y": 403.3582143919163
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 121.338624220628,
          "y": 404.3765544439379
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 121.5434954716609,
          "y": 405.3788321978673
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 121.9239748441256,
          "y": 406.3811099517967
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 122.13372450608473,
          "y": 407.57584587205645
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 122.3483525789701,
          "y": 408.7946511218898
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 122.75322400606595,
          "y": 410.02541868381593
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 123.18248748779297,
          "y": 411.0615574985884
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 123.59710106482872,
          "y": 412.27230748181097
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 123.79222282996544,
          "y": 413.238553594074
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 123.98734459510216,
          "y": 414.2007479554669
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 124.37757345346304,
          "y": 415.1629423168598
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 124.76780231182391,
          "y": 416.1251366782527
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 124.96292407696063,
          "y": 417.2196399996066
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 125.0751201923077,
          "y": 417.64058797393363
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 125.20682995135967,
          "y": 418.08960879357505
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 125.36779550405649,
          "y": 418.54263312895716
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 125.5287610567533,
          "y": 418.9916539485986
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 125.56778834416316,
          "y": 419.43266773675856
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 125.73363964374248,
          "y": 419.87368152491854
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 125.89949094332181,
          "y": 419.92355664872446
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 125.92387566199669,
          "y": 420.35656340540305
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 126.09461270845853,
          "y": 420.9338414341353
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 126.26533508300781,
          "y": 421.38691400464677
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 126.28484872671274,
          "y": 421.8439418557696
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 126.44581427940955,
          "y": 422.28495564392955
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 126.60190875713641,
          "y": 422.56558762681425
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 126.60679450401892,
          "y": 422.83821257821756
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 126.61166557898888,
          "y": 423.1108375296209
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 126.75800323486328,
          "y": 423.2471500053225
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 148.91358889066257,
          "y": 418.9284659290766
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 148.91358889066257,
          "y": 415.74118504818017
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 148.91358889066257,
          "y": 412.261261637177
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 148.91358889066257,
          "y": 409.57736256676264
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 148.91358889066257,
          "y": 406.52239064582716
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 148.91358889066257,
          "y": 403.13466868468373
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 148.91358889066257,
          "y": 400.44676609852866
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 148.91358889066257,
          "y": 397.6964954900516
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 148.91358889066257,
          "y": 394.55732915073776
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 149.08919701209436,
          "y": 393.0160479432599
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 149.28918985220102,
          "y": 391.1277390972698
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 149.3330882145808,
          "y": 388.9587741508303
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 149.70380342923679,
          "y": 386.97023270593434
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.09404695951022,
          "y": 385.1621147625819
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.36233755258414,
          "y": 383.5967642255304
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.79646477332483,
          "y": 381.8608060357695
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 151.22572091909555,
          "y": 380.3373235910425
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 151.65010598989633,
          "y": 379.05441385422836
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.0549774169922,
          "y": 378.0039974411517
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.45984884408804,
          "y": 376.9856815066948
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.8598345243014,
          "y": 375.9914107842468
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 153.0695841862605,
          "y": 374.9971400617988
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 153.2598202045147,
          "y": 374.57843502098916
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 153.6354211660532,
          "y": 373.78060186304754
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 154.02566469632663,
          "y": 372.8144039859139
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 154.4158935546875,
          "y": 371.84818199121554
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 154.80613708496094,
          "y": 370.88198411408194
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 155.21099384014423,
          "y": 369.69527934286833
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 155.5329396174504,
          "y": 368.71304328520716
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 155.83537175105167,
          "y": 367.8310157088872
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 156.25975682185245,
          "y": 366.3356061094745
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 156.85973534217248,
          "y": 364.75600209168346
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 157.47435643122745,
          "y": 363.30467940054797
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 158.12311906080978,
          "y": 362.22445367749833
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 158.95237555870642,
          "y": 360.7571169234
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 159.59626711331882,
          "y": 359.63854427247253
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 160.04503690279446,
          "y": 358.7445061489304
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 160.45965047983023,
          "y": 357.9025860827116
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 160.82062354454627,
          "y": 357.3934401742655
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 161.16695404052734,
          "y": 357.0446278359653
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 161.50841346153845,
          "y": 356.7439541568123
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 161.84498713566705,
          "y": 356.45929454062224
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 162.17668973482571,
          "y": 356.3109474001338
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 162.347412109375,
          "y": 356.1666278929507
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 162.51326340895432,
          "y": 356.030315417249
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 162.82545236440805,
          "y": 356.02631190150834
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 163.1278844980093,
          "y": 356.02631190150834
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 163.64982311542218,
          "y": 356.02631190150834
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 163.98639678955078,
          "y": 356.02631190150834
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 164.2985857450045,
          "y": 356.1385550476364
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 164.46443704458383,
          "y": 356.363065457457
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 164.63517409104566,
          "y": 356.7439541568123
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 164.81565328744742,
          "y": 357.465599927857
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 164.84003800612228,
          "y": 358.39571792819487
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 165.0302740243765,
          "y": 359.36994695437465
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 165.22052471454327,
          "y": 360.36822119256334
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 165.42051755464993,
          "y": 361.366495430752
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 165.80587533804086,
          "y": 362.3527591217186
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 166.0107392531175,
          "y": 363.5715402539872
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 166.21561784010666,
          "y": 364.7822902372098
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 166.60584669846753,
          "y": 365.74448459860275
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 167.0107181255634,
          "y": 366.53204367398087
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 167.61071131779596,
          "y": 367.7147449294538
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 168.4253252469576,
          "y": 368.8974461849267
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 168.97164858304538,
          "y": 370.0039841710674
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 169.60578331580527,
          "y": 371.1866854265403
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 170.5472212571364,
          "y": 372.57785891130635
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 171.70816568227914,
          "y": 374.53432399514725
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 172.89839524489182,
          "y": 376.51886192430254
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 174.0203123826247,
          "y": 377.7033237619988
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 175.36175067608173,
          "y": 379.5836014589427
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 176.56659346360425,
          "y": 381.50398666598784
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 177.7763219980093,
          "y": 383.44844120260666
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 179.1714154756986,
          "y": 385.4169409512344
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 180.36650144136868,
          "y": 387.18897901779104
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 181.35672349196213,
          "y": 388.55386435703076
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 182.2981761052058,
          "y": 390.26978085052343
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 183.42985006479117,
          "y": 391.9696591634886
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 184.60543705866885,
          "y": 393.9060825510613
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 185.5419892531175,
          "y": 395.62199904455395
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 186.4687992976262,
          "y": 397.3379155380466
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 187.40049508901743,
          "y": 399.2502695960456
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 188.32241938664362,
          "y": 400.7474638952463
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 189.24434368426984,
          "y": 402.6397762569771
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 190.12725536639874,
          "y": 404.28753651261894
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 191.44918001615085,
          "y": 406.46450849053986
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 192.28330758901743,
          "y": 407.4404980989429
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 192.95645493727463,
          "y": 408.6713138959984
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 193.5954607450045,
          "y": 409.7377443720379
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 194.214967580942,
          "y": 410.7761020027631
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 194.83445974496695,
          "y": 411.79444205478467
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 195.4588376558744,
          "y": 412.9811227084336
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 196.08321556678183,
          "y": 414.1798621444341
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 196.892943749061,
          "y": 415.40267091000817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 197.54659212552585,
          "y": 416.23033749548745
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 198.20511157696063,
          "y": 417.45710154167284
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 198.8441173846905,
          "y": 418.67590679150624
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 199.27338820237378,
          "y": 419.69825035926857
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 199.68800177940955,
          "y": 420.7125386604201
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 200.07335956280048,
          "y": 421.5264099988892
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 200.43920370248648,
          "y": 422.1358126238059
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 200.7806631234976,
          "y": 422.5647676296144
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 200.9416286761944,
          "y": 422.82938554953625
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 185.80052302433893,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 182.82498579758862,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 180.96922360933743,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 177.71563896766074,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 173.7059813279372,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 171.47188920241135,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 169.6280259352464,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 167.76739267202524,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 165.92352940486026,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 164.35771355262168,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 162.59190486027643,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 161.08461526724008,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 159.84074519230768,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 158.8553942166842,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 158.1285916841947,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 157.6630372267503,
          "y": 402.12612036393153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 156.89720740685095,
          "y": 401.8294502005193
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 156.36062622070312,
          "y": 401.53275591954235
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 155.6435805100661,
          "y": 401.3844328966186
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 155.18992497370792,
          "y": 401.23608575613014
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 154.78505354661206,
          "y": 401.12384261000216
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 154.321655860314,
          "y": 400.9995406815226
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 153.96068279559796,
          "y": 400.96748843803226
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 153.5606971153846,
          "y": 400.9394155927179
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 358.75109024409437
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 361.34100316486087
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 364.24362442956715
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 367.1600409412836
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 370.59187392826897
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 373.34216865431074
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 376.48309557584787
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 380.52833470349066
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 383.1062129594703
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 385.17670588922726
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 388.25572302217165
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 391.1062503471194
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 393.6720939383123
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 395.83478831792894
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 398.3766108146776
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 400.6137078814032
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 232.93600815993088,
          "y": 402.55813830045724
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 233.14575782189002,
          "y": 403.8388292213187
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 233.35550748384915,
          "y": 405.2380338551309
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 233.35550748384915,
          "y": 406.63723848894307
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 233.5652571458083,
          "y": 408.0363948876259
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 233.9311012854943,
          "y": 409.9327590002268
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 234.1067094069261,
          "y": 411.0472799002842
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 234.29694542518027,
          "y": 412.02954007551006
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 234.492067190317,
          "y": 413.20020666619615
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 234.69694577730618,
          "y": 414.0158144693239
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 234.89693861741284,
          "y": 415.074237913882
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 235.09693145751953,
          "y": 416.10058499738506
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 235.2969242976262,
          "y": 417.11091801792526
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 235.5018028846154,
          "y": 418.11319577185463
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 235.70179572472205,
          "y": 419.115473525784
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 235.9017885648287,
          "y": 420.1017372167506
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 236.1017814049354,
          "y": 420.9276191024419
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 236.30665999192456,
          "y": 421.7494974723924
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 236.50178175706128,
          "y": 422.54330299702866
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 236.51153857891376,
          "y": 423.00033084815146
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 236.5164096538837,
          "y": 423.0582130034388
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 236.5164096538837,
          "y": 423.49121976011736
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 236.5164096538837,
          "y": 423.75583768003924
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 236.5164096538837,
          "y": 424.0204073648317
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 236.5164096538837,
          "y": 424.2850252847536
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 236.5164096538837,
          "y": 424.69396271185855
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 248.16979628342847,
          "y": 365.2098464242655
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 250.24780860314002,
          "y": 365.2098464242655
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 252.3209205040565,
          "y": 365.2098464242655
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 253.65961514986478,
          "y": 365.2098464242655
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 256.07907221867487,
          "y": 365.2098464242655
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 259.2448601355919,
          "y": 365.2098464242655
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 260.922857431265,
          "y": 365.35816944718925
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.41064805250903,
          "y": 365.80318675109
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 263.67401709923377,
          "y": 366.41659289174737
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.92277292104865,
          "y": 366.9217352844528
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 265.92762286846454,
          "y": 367.40283246514923
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 266.43218994140625,
          "y": 368.02827327059344
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 267.4126551701472,
          "y": 368.5173774827713
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 268.13947237454926,
          "y": 368.9664224199774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 268.8516470102163,
          "y": 369.55175571532044
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 269.5638216458834,
          "y": 369.98874187017503
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 270.2759962815505,
          "y": 370.4257521425943
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 270.98820026104266,
          "y": 371.01108543793737
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 271.4369553786058,
          "y": 371.37991535494115
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 271.82232783390924,
          "y": 371.6966513321863
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 272.30523916391223,
          "y": 372.2017937248917
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 272.6808401254507,
          "y": 372.6347763640056
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 273.0515406681941,
          "y": 373.0838213012117
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 273.4076573298528,
          "y": 373.52481097180697
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 273.75884422889123,
          "y": 373.96582475996695
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.1100604717548,
          "y": 374.40283503238624
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.4612767146184,
          "y": 374.8478523362869
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.6758974515475,
          "y": 375.42915799832457
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.71979581392725,
          "y": 375.8782029355307
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.73443838266223,
          "y": 376.32322023943135
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.73443838266223,
          "y": 376.91255705051515
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.73443838266223,
          "y": 377.3615778701565
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.73443838266223,
          "y": 377.8105986897979
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.73443838266223,
          "y": 378.4079666499278
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.73443838266223,
          "y": 378.8449769223471
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.73443838266223,
          "y": 379.27795956146093
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.73443838266223,
          "y": 379.7029351690934
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.73443838266223,
          "y": 379.9675289714506
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.73443838266223,
          "y": 380.39250457908304
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.73443838266223,
          "y": 380.96580320963926
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.73443838266223,
          "y": 381.22238998051506
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.45640563964844,
          "y": 381.4950149319183
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 273.8661839411809,
          "y": 381.78768157958984
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 273.1979076678936,
          "y": 382.08435174300206
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 272.29059659517725,
          "y": 382.39708420450654
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 271.1735358605018,
          "y": 382.58951825327216
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 270.1979710505559,
          "y": 382.63763279485477
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 269.19799217811,
          "y": 382.657674491123
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 268.00777728740985,
          "y": 382.6616780068637
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 266.8077909029447,
          "y": 382.8060216316115
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 265.5931912935697,
          "y": 382.95834817027594
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.37372060922473,
          "y": 382.97438635080346
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 263.6349898118239,
          "y": 383.13476815607874
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.63988201434796,
          "y": 383.29112232804863
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 261.64967463566705,
          "y": 383.45951116480535
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 260.44971759502704,
          "y": 383.7802265402265
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 259.5033792349008,
          "y": 383.9406083455018
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 258.55707021859973,
          "y": 384.1009660332124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 257.3814978966346,
          "y": 384.42170552619825
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 256.20589623084436,
          "y": 384.7424209016194
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 255.27422978327823,
          "y": 384.9950041567545
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 254.3766902043269,
          "y": 385.2395562628434
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 253.0742645263672,
          "y": 385.6765665352627
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 251.65965740497296,
          "y": 386.2859450426147
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 250.65753643329327,
          "y": 386.943462209114
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 249.19415987454926,
          "y": 387.61699343857606
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 247.89176354041464,
          "y": 388.2945523013436
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 246.63323622483472,
          "y": 388.964080015065
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 245.6235152024489,
          "y": 389.5894967029445
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 244.79914445143478,
          "y": 390.0826285484278
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 244.0138009878305,
          "y": 390.56772924486495
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 243.4382071861854,
          "y": 391.0127465487656
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 243.20405813363882,
          "y": 391.3094167121779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 243.0089363685021,
          "y": 391.594076328368
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 242.83821399395282,
          "y": 391.870704795512
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 242.8235860971304,
          "y": 392.14332974691524
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 242.6577347975511,
          "y": 392.41595469831856
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 242.49188349797174,
          "y": 392.6845761339811
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 242.49188349797174,
          "y": 392.9491699363383
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 242.49188349797174,
          "y": 393.2137637386955
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 242.49188349797174,
          "y": 393.47838165861737
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 242.49188349797174,
          "y": 393.71089909991946
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 242.62357858511118,
          "y": 393.9314059939995
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 243.21381495549127,
          "y": 394.0597114382197
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 244.01867206280048,
          "y": 394.19602391392135
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 244.74548926720252,
          "y": 394.3403434211044
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 245.4918054433969,
          "y": 394.36841626641876
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 246.22837946965143,
          "y": 394.5047287421204
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 247.1649463360126,
          "y": 394.77735369352365
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 248.1112553523137,
          "y": 395.0740238569359
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 249.08684950608472,
          "y": 395.38673220087566
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 250.05267216609073,
          "y": 395.8317495047764
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 251.02339524489182,
          "y": 396.43712449638764
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 252.5404123159555,
          "y": 397.5075826057326
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 253.92303466796875,
          "y": 399.0230339014135
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 255.8156527005709,
          "y": 400.57056155814945
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 258.10828575721155,
          "y": 402.4548668883988
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 260.7765197753906,
          "y": 404.8643563076218
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 263.42524014986475,
          "y": 407.4783626755267
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 266.07396052433893,
          "y": 410.0923208083022
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 267.60779160719653,
          "y": 412.1570255225304
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 270.6174703744741,
          "y": 415.1037578130785
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 272.73448063777045,
          "y": 416.9159033897364
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.69054002028247,
          "y": 418.5556566138968
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 276.4563633845403,
          "y": 420.16328524187276
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 278.1538743239183,
          "y": 421.5785521737772
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 279.1169680081881,
          "y": 422.640979134076
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 280.24864196777344,
          "y": 423.56309010293245
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 281.17059560922473,
          "y": 424.4290553811602
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 281.8339714637169,
          "y": 424.9783087997075
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 282.5803023118239,
          "y": 425.3511663500167
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 283.1412682166466,
          "y": 425.6638746939564
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 283.53151174692005,
          "y": 426.0928296997649
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 283.8973412146935,
          "y": 426.52583645644347
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 284.2339148888221,
          "y": 426.81447547080955
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 284.5656174879808,
          "y": 427.09110393795356
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 284.73636920635516,
          "y": 427.35572185787544
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 284.89244901216944,
          "y": 427.4880308178364
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 462.98107902924596
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 465.42264480952406
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 468.16891190226045
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 472.39233159792934
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 475.29492874507093
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 477.9266856405972
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 481.5750461234866
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 484.4014800012959
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 486.92326080177634
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 489.32247613843583
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 492.12488892180096
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 494.7348435388357
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 497.0441003591528
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 499.190756558242
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 501.4920545822071
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 503.74116219615485
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 506.2428771828023
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 508.0968908517847
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 510.3339879185103
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 512.5269980769586
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 63.10096153846153,
          "y": 514.4193104386894
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.86681982187124,
          "y": 516.3116228004202
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.632678105280945,
          "y": 517.5642408759674
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.310732327974755,
          "y": 519.9095775730802
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.22781401414137,
          "y": 521.1604591839687
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.18878672673152,
          "y": 522.3511915884877
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 62.00342911940354,
          "y": 523.5860109012839
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.813185765193055,
          "y": 524.8127749474693
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.76928740281325,
          "y": 525.8631913605459
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.74001693725586,
          "y": 526.6587815849701
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.7302601154034,
          "y": 527.8535175052299
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.540024097149185,
          "y": 529.1364272420441
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.34002758906438,
          "y": 530.3592360076182
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.31076079148512,
          "y": 531.3855830911211
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.2912471477802,
          "y": 532.5963330743438
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 61.10588587247408,
          "y": 533.7990760260849
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.910767775315506,
          "y": 534.6530307570905
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.891257799588715,
          "y": 535.4909231900039
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.88637938866248,
          "y": 535.8976418011562
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.88150097773625,
          "y": 536.4949856437213
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.88150097773625,
          "y": 537.0803189390643
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.88150097773625,
          "y": 537.6656522344074
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.88150097773625,
          "y": 538.0906519596045
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.88150097773625,
          "y": 538.355221644397
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.88150097773625,
          "y": 538.6198395643189
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.49614319434532,
          "y": 538.9486102063508
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 60.17420108501727,
          "y": 538.9926971146281
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 59.88152577326848,
          "y": 538.9967006303688
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 105.70972149188701,
          "y": 469.95298463360393
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 103.87561504657451,
          "y": 470.85502978862746
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 102.70003538865309,
          "y": 471.51657458843215
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 100.7927601154034,
          "y": 472.6190849412674
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 98.91475530771109,
          "y": 473.7296023255841
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 97.74678303645207,
          "y": 474.5234078502203
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 95.87853505061223,
          "y": 475.6379769854071
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 93.98589501014122,
          "y": 476.9248902379619
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 89.88843330970177,
          "y": 479.34243492379574
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 87.96652280367337,
          "y": 480.5171050302225
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 86.0348628117488,
          "y": 481.70383391900083
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 84.8864041841947,
          "y": 482.81835481905824
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 83.52058117206279,
          "y": 483.9048511089307
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 82.13524451622595,
          "y": 485.0273790404695
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 80.7938135587252,
          "y": 486.12588587756403
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 79.52067301823543,
          "y": 487.00791345388404
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 78.51094465989333,
          "y": 487.8337953395753
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 77.75486432589017,
          "y": 488.6115868012487
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 77.03780394334059,
          "y": 489.1969200965917
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 76.48172378540039,
          "y": 489.97070804252445
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 75.89148741502028,
          "y": 490.93290240391735
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 75.21131222064679,
          "y": 492.0394162724933
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 74.47962394127478,
          "y": 492.7570585277973
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 73.86988126314603,
          "y": 493.5067771441564
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 73.28453063964844,
          "y": 494.144204379258
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 72.73332155667818,
          "y": 494.637360342306
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 72.18699088463416,
          "y": 495.10639874065095
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 71.78212679349458,
          "y": 495.5554195602923
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 71.55286348783052,
          "y": 495.86412438849135
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 71.3577417226938,
          "y": 496.1528116379869
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 71.32360018216646,
          "y": 496.4294401051309
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 71.30408653846153,
          "y": 496.7060685722749
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 71.29920812753531,
          "y": 496.97869352367815
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 71.29432971660907,
          "y": 497.25131847508146
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 71.28945130568285,
          "y": 497.5239434264847
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 71.28457289475661,
          "y": 497.79656837788804
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 71.4455457834097,
          "y": 498.0651898135506
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 71.7674915607159,
          "y": 498.3297594983431
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 72.0894300020658,
          "y": 498.59437741826497
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 72.60649754450871,
          "y": 498.871005885409
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 73.12355775099535,
          "y": 499.1476343525529
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 73.89914439274715,
          "y": 499.4804085103256
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 74.42108301016,
          "y": 499.52849893434353
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 74.93327214167668,
          "y": 499.64879734708234
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 75.46008917001578,
          "y": 499.76904752469176
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 75.98202778742863,
          "y": 499.897352968912
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 76.50396640484149,
          "y": 500.02565841313225
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 77.045411330003,
          "y": 500.16597440457457
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 77.12072959313025,
          "y": 500.3022868802762
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 77.82315474290114,
          "y": 500.45060990319996
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 78.54508620042067,
          "y": 500.72723837034397
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 79.27189606886644,
          "y": 501.1441828289303
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 80.21334134615384,
          "y": 501.74157490662486
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 81.6718394939716,
          "y": 502.6035366691119
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 83.24741950401892,
          "y": 503.6980399904658
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 85.18883631779597,
          "y": 505.07317529470436
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 86.87171936035156,
          "y": 506.4082272064064
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 88.23266396155724,
          "y": 507.49067174540875
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 90.08140563964844,
          "y": 508.8217201413701
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 91.64234308096079,
          "y": 510.10467811331364
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 92.7225112915039,
          "y": 511.3875878501277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 94.28344873281625,
          "y": 512.6704975869418
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 96.36632479154146,
          "y": 514.643024968875
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 97.62971584613506,
          "y": 515.7455353217102
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 98.90285638662485,
          "y": 516.8199728292311
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 100.024780860314,
          "y": 517.8823997895299
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 101.122313279372,
          "y": 518.928812686866
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 101.91468458909254,
          "y": 520.0072537101275
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 103.21221718421349,
          "y": 521.09375
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 104.30974960327148,
          "y": 522.1361111464659
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 105.40241094735953,
          "y": 523.1825240438019
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 106.47555131178636,
          "y": 524.2328922217491
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 107.54382060124324,
          "y": 525.2753016033444
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 108.57306993924654,
          "y": 526.1412668815722
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 109.37792704655574,
          "y": 526.9631452515227
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 110.00717603243314,
          "y": 527.9494089424893
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 110.81691888662485,
          "y": 528.783297859662
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 111.59738393930289,
          "y": 529.4247768456337
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 112.1729850769043,
          "y": 530.2065718230479
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 112.91930858905498,
          "y": 530.9803115338511
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 113.63148322472205,
          "y": 531.5656448291942
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 114.3436651963454,
          "y": 532.1509781245371
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 114.7165298461914,
          "y": 533.0570750304308
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 115.19944851215068,
          "y": 533.606328448978
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 115.58480629554161,
          "y": 534.0713633315823
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 115.95065043522762,
          "y": 534.4161480365772
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 116.28722410935622,
          "y": 534.7168458332948
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 116.61892670851486,
          "y": 534.9974778161794
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 116.95062930767352,
          "y": 535.2741062833235
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 117.15550055870642,
          "y": 535.5547382662081
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 117.34573657696063,
          "y": 535.7191235872242
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 473.1723418755554
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 475.3693555497445
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 477.5863868026372
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 479.72903948598565
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 482.52344523786934
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 484.5761394048754
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 486.60876775804854
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 488.6993265016384
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 491.18898270593434
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 493.6546178157861
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 496.0961835960641
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 497.8699822448441
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 499.7623428417043
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 501.6546552034351
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 504.64147088651976
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 506.27717235981004
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 150.9281745323768,
          "y": 507.7445332314731
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 151.0988969069261,
          "y": 508.99715130702015
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 151.29888974703275,
          "y": 510.66898089223565
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 151.3428027813251,
          "y": 512.1764251564351
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 151.53790987454929,
          "y": 513.6357789966168
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 151.733031639686,
          "y": 514.8986229195979
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 151.76717318021335,
          "y": 516.1855844072821
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 151.79157257080078,
          "y": 517.6649558261672
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 151.79157257080078,
          "y": 518.5126399903501
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 151.98667966402493,
          "y": 519.7474593031463
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.19155825101413,
          "y": 521.1627262350507
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.20618614783655,
          "y": 522.4135596108098
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.21107189471905,
          "y": 523.6523824393466
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.4208215566782,
          "y": 525.0716046518625
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.63057121863733,
          "y": 526.2583335406407
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.63057121863733,
          "y": 527.2205279020336
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.63057121863733,
          "y": 527.7875800833318
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.63057121863733,
          "y": 528.7497744447247
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.7622809776893,
          "y": 530.1209062332225
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.91348970853366,
          "y": 530.9107082421181
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.947631249061,
          "y": 531.6764891565693
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.96714489276593,
          "y": 532.3219234231524
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.97203063964844,
          "y": 532.9553953776427
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.97203063964844,
          "y": 533.4364925583392
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.97203063964844,
          "y": 533.8975239252027
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 152.97203063964844,
          "y": 534.3425412291034
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 153.10861147367038,
          "y": 534.6472425415618
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 153.25494912954477,
          "y": 534.9359297910573
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 153.2744627732497,
          "y": 535.2125582582013
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 153.43055725097656,
          "y": 535.3688883126065
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 153.73298938457782,
          "y": 535.3889541264394
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 154.04029259314905,
          "y": 535.3889541264394
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 154.35248154860275,
          "y": 535.3889541264394
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 154.67442732590894,
          "y": 535.3889541264394
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 154.92320427527795,
          "y": 535.3889541264394
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 155.15733865591196,
          "y": 535.3889541264394
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 155.46465653639572,
          "y": 535.2887215275335
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 155.76708866999698,
          "y": 535.0922598454624
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 156.2451142531175,
          "y": 534.8356971921514
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 156.75242497370792,
          "y": 534.5510134583966
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 157.2792493380033,
          "y": 534.3826246216399
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 158.18165999192456,
          "y": 534.3225236503998
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 159.1084700364333,
          "y": 534.1741523923467
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 160.0499079777644,
          "y": 534.0338364009043
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 161.21573814978964,
          "y": 533.8694510798883
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 162.21083127535306,
          "y": 533.7051139940017
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 163.19129650409405,
          "y": 533.6810446644281
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 164.38151139479416,
          "y": 533.669034117206
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 165.59612567608173,
          "y": 533.5126558276714
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 166.8351100041316,
          "y": 533.3522740223962
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 167.82533205472507,
          "y": 533.3442669909148
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 168.78141256479117,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 169.72285050612228,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 170.66428844745343,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 171.6057410606971,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 172.54717900202823,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 173.89348837045523,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 174.76175748384915,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 175.66905388465293,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 176.62513439471905,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 177.7763219980093,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 178.76654404860275,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 179.76652292104868,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 180.95185206486627,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 182.15182377741885,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 183.36643805870642,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 184.36641693115234,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 185.3468821598933,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 186.3224616417518,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 187.08342038668118,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 187.6297583946815,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 187.9663320688101,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 188.28827784611627,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 188.61020895150992,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 188.9321547288161,
          "y": 533.340263475174
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 189.09313495342548,
          "y": 533.1077219163072
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 169.9862553523137,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 171.2350111741286,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 172.2593747652494,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 173.46910329965445,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 174.4593253502479,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 175.46417529766376,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 176.66903275709885,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 177.64949798583984,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 178.12968034010666,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 179.0711182814378,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 180.0125708946815,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 180.9540088360126,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 181.91496042104868,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 182.4417701134315,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 182.9588376558744,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 183.3393096923828,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 183.6954116821289,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 184.03685643122745,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 184.53441032996543,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 185.04659212552585,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 185.40756519024188,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 185.75389568622296,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 186.09046936035156,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 186.42217195951022,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 186.75387455866885,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 187.08556248591495,
          "y": 496.31314520813277
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 168.95702068622296,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 170.46918135422925,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 172.23011897160455,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 174.1959205040565,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 176.00562755878155,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 177.26141122671274,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 178.94918001615085,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 180.7588870708759,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 182.60275033804086,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 184.12466782789963,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 185.41243626521182,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 186.6367926964393,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 187.84652123084433,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 188.59012310321515,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 189.57058833195612,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 190.5168973482572,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 191.4583499615009,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 192.39978790283203,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 193.34122584416315,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 194.4631576538086,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 195.01435206486627,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 195.55093325101413,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 196.0874997652494,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 196.46798647367038,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 196.82894486647385,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 197.16553321251502,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 197.66795818622296,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 198.18013998178336,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 198.53622729961688,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 198.4017885648287,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 198.73836223895734,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 199.0749505849985,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 199.40665318415714,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 199.73834111140323,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 200.06517263559195,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 200.38710374098557,
          "y": 462.84476655354433
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 200.70904951829175,
          "y": 462.7084540778427
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 201.03099529559796,
          "y": 462.572141602141
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 242.38944420447714,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 244.03331463153546,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 245.78448075514572,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 247.7844531719501,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 249.33289747971753,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 251.1377187875601,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 252.93279794546274,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 254.48400057279144,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 256.0107891376202,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 257.50342149000903,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 259.00582533616284,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 260.2421100323017,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 261.50060800405646,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.52497159517725,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 263.72005756084735,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.89075880784253,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 266.06148939866284,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 267.232190645658,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 268.40289189265326,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 269.49066748985877,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 270.6028278057392,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 271.64183396559497,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 272.18542832594653,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 273.1658935546875,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.15122985839844,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 274.97074420635516,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 275.59022169846753,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 276.34142362154444,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 277.0487271822416,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 277.79505803034857,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 278.55113102839545,
          "y": 462.73652692315704
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 279.29259080153247,
          "y": 462.5961626965853
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 280.0388923058143,
          "y": 462.4518431894022
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 280.7657095102163,
          "y": 462.4398326421801
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 281.7266610952524,
          "y": 462.4358291264394
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 282.68761268028845,
          "y": 462.28745786838624
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 283.4046877347506,
          "y": 462.1391348454625
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 284.11686237041766,
          "y": 461.9908118225387
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 284.3482677753155,
          "y": 461.8424405644855
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 285.06044241098255,
          "y": 461.8424405644855
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 285.8994410588191,
          "y": 461.65803766476597
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 286.3579676701472,
          "y": 461.51772167332365
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 286.8652930626502,
          "y": 461.381409197622
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 287.40184490497296,
          "y": 461.23708969043895
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 287.89939880371094,
          "y": 461.104780730478
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 288.59696021446814,
          "y": 460.95245419181356
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 289.33839064378003,
          "y": 460.796075902279
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 290.07007892315204,
          "y": 460.64374936361446
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 290.80663827749396,
          "y": 460.4913745898206
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 291.54322697566107,
          "y": 460.3350445354154
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 292.279786330003,
          "y": 460.05436431740134
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 292.8407522348257,
          "y": 459.77373233451664
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 293.3919466458834,
          "y": 459.4810898044098
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 293.9236567570613,
          "y": 459.31670448339383
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 294.2699725811298,
          "y": 459.1683814604701
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 294.4455953744741,
          "y": 458.89575650906676
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 294.4504664494441,
          "y": 458.6351421048856
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 467.4071827386793
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 468.73417938377054
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 470.0492137167691
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 471.94152607849986
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 473.63337324241894
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 474.70559193398714
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 476.405494364517
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 478.07327219886236
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 479.4724768326746
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 480.8716814664868
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 482.270886100299
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 483.274900318887
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 484.37340715598157
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 485.60021943729635
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 487.3201394465297
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 489.1683648999833
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 490.92436478601246
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 262.95970036433295,
          "y": 492.4879547408407
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 263.12555166391223,
          "y": 493.9713296754665
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 263.31578768216644,
          "y": 495.04755188277545
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 263.34991455078125,
          "y": 496.49084342486486
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 263.55479313777045,
          "y": 497.9542007807872
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 263.7548006497897,
          "y": 499.42556516819093
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 263.77918536846454,
          "y": 500.6763985439499
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 263.7938279371995,
          "y": 501.9312836705791
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 263.9938061053936,
          "y": 503.36656818118706
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.2035557673528,
          "y": 504.4307316061445
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.2133272611178,
          "y": 505.8820301797153
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.2133272611178,
          "y": 507.09282839806724
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.4230769230769,
          "y": 508.27950905171616
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 509.4622344247538
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 510.644911562662
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 511.8276369356996
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 513.804119598244
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 514.7761056909064
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 516.1713068089779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 517.6025878038452
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 519.0458793459345
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 520.501181435246
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 521.9404694615947
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 522.800212408129
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 524.0550492996288
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 525.289868612425
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 526.316215695928
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 527.3265487164683
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 528.3288264703976
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 529.1467013246075
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 529.7761215282278
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 530.3895276688852
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 530.8225344255637
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 531.2474859156315
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 531.6724374056993
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 531.9370553256211
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 531.8065310654482
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 532.2154684925531
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.63282658503607,
          "y": 532.4680276301235
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.7547795222356,
          "y": 532.7286420343047
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.89134568434497,
          "y": 533.001266985708
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.9206308218149,
          "y": 533.2738919371112
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.94014446551984,
          "y": 533.5465168885145
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 264.94501554048975,
          "y": 533.6106213754953
      }
  }
]