import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ErrorPage } from "../routes/Error";
import { Home } from "../routes/Home/Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/gallery",
    element: <div> gallery page </div>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: <div> about page </div>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/product",
    element: <div> product page </div>,
    errorElement: <ErrorPage />,
  },
]);

export const App = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
