import { GuestLineData, Point } from "../models"

export const baseCoordsDesktop : Point = { x: 2000, y: 977
 }
export const kodamaMarketDesktopPath: GuestLineData[] = [
  {
      "brushType": "pine",
      "point": {
          "x": 87,
          "y": 162.21832884097034
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 87,
          "y": 163.21967654986523
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 87,
          "y": 167.22506738544476
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 86,
          "y": 172.23180592991915
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 86,
          "y": 179.2412398921833
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 85,
          "y": 185.24932614555257
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 85,
          "y": 192.25876010781673
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 202.2722371967655
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 211.28436657681942
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 219.29514824797843
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 229.30862533692724
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 238.32075471698113
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 247.33288409703505
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 254.3423180592992
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 265.3571428571429
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 270.3638814016173
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 274.36927223719675
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 278.3746630727763
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 284.3827493261456
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 289.38948787062
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 294.39622641509436
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 299.40296495956875
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 303.4083557951483
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 308.41509433962267
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 311.4191374663073
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 315.4245283018868
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 320.4312668463612
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 323.43530997304583
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 327.44070080862537
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 330.44474393531
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 334.4501347708895
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 337.45417789757414
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 342.46091644204853
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 345.46495956873315
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 349.4703504043127
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 352.4743935309973
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 354.47708894878707
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 356.47978436657684
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 358.4824797843666
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 360.48517520215637
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 361.4865229110512
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 196,
          "y": 194.26145552560646
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 196,
          "y": 193.26010781671158
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 194,
          "y": 193.26010781671158
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 192,
          "y": 193.26010781671158
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 189,
          "y": 194.26145552560646
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 184,
          "y": 197.26549865229111
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 178,
          "y": 200.26954177897574
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 171,
          "y": 203.27358490566039
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 164,
          "y": 208.28032345013477
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 157,
          "y": 211.28436657681942
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 150,
          "y": 215.28975741239893
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 142,
          "y": 220.29649595687331
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 135,
          "y": 224.30188679245285
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 127,
          "y": 229.30862533692724
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 119,
          "y": 233.31401617250674
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 114,
          "y": 236.3180592991914
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 110,
          "y": 239.322102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 99,
          "y": 244.3288409703504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 95,
          "y": 246.33153638814017
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 92,
          "y": 247.33288409703505
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 90,
          "y": 248.33423180592993
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 87,
          "y": 249.33557951482481
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 85,
          "y": 250.33692722371967
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 251.33827493261455
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 251.33827493261455
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 82,
          "y": 252.33962264150944
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 81,
          "y": 252.33962264150944
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 81,
          "y": 252.33962264150944
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 81,
          "y": 253.34097035040432
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 81,
          "y": 253.34097035040432
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 82,
          "y": 253.34097035040432
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 82,
          "y": 253.34097035040432
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 83,
          "y": 253.34097035040432
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 84,
          "y": 253.34097035040432
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 86,
          "y": 253.34097035040432
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 90,
          "y": 254.3423180592992
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 101,
          "y": 258.34770889487874
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 106,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 113,
          "y": 262.3530997304582
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 120,
          "y": 265.3571428571429
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 126,
          "y": 268.3611859838275
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 133,
          "y": 271.36522911051213
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 140,
          "y": 275.37061994609167
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 148,
          "y": 278.3746630727763
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 155,
          "y": 282.3800539083558
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 161,
          "y": 285.38409703504044
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 167,
          "y": 288.38814016172506
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 173,
          "y": 290.3908355795148
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 177,
          "y": 293.39487870619945
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 182,
          "y": 295.3975741239892
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 186,
          "y": 297.400269541779
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 191,
          "y": 299.40296495956875
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 195,
          "y": 301.4056603773585
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 198,
          "y": 303.4083557951483
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 202,
          "y": 305.411051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 204,
          "y": 306.4123989218329
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 209,
          "y": 308.41509433962267
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 210,
          "y": 309.4164420485175
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 211,
          "y": 310.4177897574124
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 213,
          "y": 310.4177897574124
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 214,
          "y": 311.4191374663073
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 215,
          "y": 312.42048517520215
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 217,
          "y": 313.42183288409706
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 218,
          "y": 314.4231805929919
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 220,
          "y": 314.4231805929919
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 221,
          "y": 315.4245283018868
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 222,
          "y": 316.4258760107817
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 223,
          "y": 317.42722371967653
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 225,
          "y": 318.42857142857144
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 225,
          "y": 319.4299191374663
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 226,
          "y": 319.4299191374663
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 227,
          "y": 319.4299191374663
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 227,
          "y": 320.4312668463612
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 227,
          "y": 320.4312668463612
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 301,
          "y": 185.24932614555257
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 300,
          "y": 185.24932614555257
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 298,
          "y": 185.24932614555257
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 296,
          "y": 185.24932614555257
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 294,
          "y": 185.24932614555257
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 292,
          "y": 185.24932614555257
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 289,
          "y": 185.24932614555257
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 286,
          "y": 185.24932614555257
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 285,
          "y": 185.24932614555257
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 283,
          "y": 185.24932614555257
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 281,
          "y": 185.24932614555257
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 280,
          "y": 185.24932614555257
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 279,
          "y": 187.2520215633423
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 278,
          "y": 188.2533692722372
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 278,
          "y": 190.25606469002696
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 277,
          "y": 193.26010781671158
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 277,
          "y": 196.26415094339623
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 276,
          "y": 200.26954177897574
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 276,
          "y": 205.27628032345015
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 276,
          "y": 214.28840970350404
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 276,
          "y": 218.29380053908358
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 276,
          "y": 223.30053908355796
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 276,
          "y": 228.30727762803235
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 276,
          "y": 233.31401617250674
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 276,
          "y": 239.322102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 276,
          "y": 245.33018867924528
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 278,
          "y": 257.3463611859838
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 279,
          "y": 263.3544474393531
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 281,
          "y": 270.3638814016173
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 283,
          "y": 276.3719676549865
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 285,
          "y": 282.3800539083558
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 286,
          "y": 285.38409703504044
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 288,
          "y": 290.3908355795148
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 290,
          "y": 294.39622641509436
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 293,
          "y": 298.40161725067384
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 295,
          "y": 302.40700808625337
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 298,
          "y": 305.411051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 301,
          "y": 309.4164420485175
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 304,
          "y": 312.42048517520215
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 309,
          "y": 316.4258760107817
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 313,
          "y": 319.4299191374663
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 316,
          "y": 321.43261455525607
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 320,
          "y": 323.43530997304583
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 324,
          "y": 325.4380053908356
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 329,
          "y": 326.43935309973045
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 333,
          "y": 327.44070080862537
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 336,
          "y": 328.4420485175202
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 340,
          "y": 328.4420485175202
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 344,
          "y": 328.4420485175202
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 348,
          "y": 327.44070080862537
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 352,
          "y": 327.44070080862537
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 356,
          "y": 325.4380053908356
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 360,
          "y": 323.43530997304583
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 364,
          "y": 321.43261455525607
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 368,
          "y": 318.42857142857144
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 372,
          "y": 316.4258760107817
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 376,
          "y": 313.42183288409706
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 379,
          "y": 309.4164420485175
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 382,
          "y": 305.411051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 386,
          "y": 301.4056603773585
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 390,
          "y": 291.3921832884097
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 392,
          "y": 287.3867924528302
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 394,
          "y": 280.37735849056605
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 396,
          "y": 272.366576819407
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 397,
          "y": 266.35849056603774
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 399,
          "y": 252.33962264150944
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 399,
          "y": 245.33018867924528
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 400,
          "y": 239.322102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 400,
          "y": 234.31536388140162
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 400,
          "y": 229.30862533692724
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 398,
          "y": 224.30188679245285
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 397,
          "y": 220.29649595687331
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 394,
          "y": 216.2911051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 391,
          "y": 212.28571428571428
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 388,
          "y": 209.28167115902966
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 385,
          "y": 206.277628032345
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 381,
          "y": 203.27358490566039
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 377,
          "y": 201.27088948787062
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 374,
          "y": 199.26819407008088
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 370,
          "y": 198.266846361186
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 365,
          "y": 196.26415094339623
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 361,
          "y": 195.26280323450135
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 357,
          "y": 195.26280323450135
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 352,
          "y": 194.26145552560646
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 345,
          "y": 194.26145552560646
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 339,
          "y": 193.26010781671158
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 333,
          "y": 193.26010781671158
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 325,
          "y": 192.25876010781673
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 319,
          "y": 192.25876010781673
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 316,
          "y": 192.25876010781673
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 312,
          "y": 192.25876010781673
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 308,
          "y": 192.25876010781673
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 305,
          "y": 191.25741239892184
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 301,
          "y": 191.25741239892184
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 299,
          "y": 191.25741239892184
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 297,
          "y": 190.25606469002696
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 295,
          "y": 190.25606469002696
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 294,
          "y": 190.25606469002696
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 293,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 292,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 291,
          "y": 188.2533692722372
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 442,
          "y": 171.23045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 442,
          "y": 170.22911051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 442,
          "y": 171.23045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 442,
          "y": 171.23045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 442,
          "y": 173.23315363881403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 442,
          "y": 177.23854447439354
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 442,
          "y": 181.24393530997304
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 442,
          "y": 186.25067385444746
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 442,
          "y": 191.25741239892184
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 442,
          "y": 198.266846361186
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 442,
          "y": 206.277628032345
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 442,
          "y": 215.28975741239893
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 443,
          "y": 226.3045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 443,
          "y": 233.31401617250674
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 443,
          "y": 240.3234501347709
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 443,
          "y": 249.33557951482481
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 444,
          "y": 256.34501347708897
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 444,
          "y": 262.3530997304582
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 444,
          "y": 268.3611859838275
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 445,
          "y": 273.3679245283019
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 445,
          "y": 277.37331536388143
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 445,
          "y": 282.3800539083558
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 445,
          "y": 284.3827493261456
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 445,
          "y": 286.3854447439353
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 446,
          "y": 288.38814016172506
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 446,
          "y": 289.38948787062
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 446,
          "y": 291.3921832884097
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 446,
          "y": 293.39487870619945
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 447,
          "y": 294.39622641509436
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 447,
          "y": 296.3989218328841
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 447,
          "y": 297.400269541779
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 448,
          "y": 299.40296495956875
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 448,
          "y": 300.4043126684636
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 448,
          "y": 301.4056603773585
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 448,
          "y": 302.40700808625337
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 303.4083557951483
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 304.40970350404314
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 304.40970350404314
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 305.411051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 306.4123989218329
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 307.41374663072776
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 310.4177897574124
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 312.42048517520215
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 315.4245283018868
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 319.4299191374663
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 322.433962264151
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 325.4380053908356
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 328.4420485175202
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 330.44474393531
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 332.44743935309975
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 333.4487870619946
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 334.4501347708895
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 334.4501347708895
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 449,
          "y": 335.4514824797844
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 430,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 431,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 432,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 435,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 440,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 446,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 454,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 462,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 471,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 480,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 492,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 498,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 503,
          "y": 190.25606469002696
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 508,
          "y": 190.25606469002696
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 512,
          "y": 191.25741239892184
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 515,
          "y": 193.26010781671158
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 517,
          "y": 194.26145552560646
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 519,
          "y": 196.26415094339623
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 522,
          "y": 198.266846361186
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 524,
          "y": 201.27088948787062
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 527,
          "y": 204.27493261455527
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 529,
          "y": 208.28032345013477
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 532,
          "y": 212.28571428571428
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 534,
          "y": 216.2911051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 536,
          "y": 219.29514824797843
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 538,
          "y": 223.30053908355796
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 539,
          "y": 226.3045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 541,
          "y": 230.30997304582212
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 542,
          "y": 234.31536388140162
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 543,
          "y": 238.32075471698113
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 544,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 545,
          "y": 249.33557951482481
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 546,
          "y": 255.34366576819409
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 547,
          "y": 261.35175202156336
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 547,
          "y": 267.3598382749326
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 548,
          "y": 272.366576819407
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 548,
          "y": 281.3787061994609
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 548,
          "y": 284.3827493261456
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 548,
          "y": 288.38814016172506
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 548,
          "y": 293.39487870619945
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 547,
          "y": 297.400269541779
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 545,
          "y": 301.4056603773585
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 543,
          "y": 305.411051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 541,
          "y": 309.4164420485175
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 538,
          "y": 314.4231805929919
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 535,
          "y": 318.42857142857144
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 532,
          "y": 321.43261455525607
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 528,
          "y": 325.4380053908356
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 524,
          "y": 328.4420485175202
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 516,
          "y": 333.4487870619946
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 511,
          "y": 335.4514824797844
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 507,
          "y": 336.45283018867923
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 503,
          "y": 338.455525606469
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 499,
          "y": 338.455525606469
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 495,
          "y": 339.4568733153639
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 491,
          "y": 339.4568733153639
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 487,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 484,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 481,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 478,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 474,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 471,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 468,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 462,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 460,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 459,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 458,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 457,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 457,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 456,
          "y": 339.4568733153639
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 456,
          "y": 339.4568733153639
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 456,
          "y": 339.4568733153639
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 348.46900269541777
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 347.4676549865229
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 345.46495956873315
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 342.46091644204853
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 338.455525606469
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 333.4487870619946
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 328.4420485175202
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 321.43261455525607
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 314.4231805929919
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 306.4123989218329
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 300.4043126684636
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 296.3989218328841
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 290.3908355795148
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 283.3814016172507
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 273.3679245283019
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 268.3611859838275
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 264.355795148248
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 254.3423180592992
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 249.33557951482481
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 246.33153638814017
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 240.3234501347709
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 237.31940700808624
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 235.3167115902965
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 232.31266846361186
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 230.30997304582212
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 227.30592991913747
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 225.3032345013477
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 591,
          "y": 223.30053908355796
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 592,
          "y": 220.29649595687331
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 592,
          "y": 217.2924528301887
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 593,
          "y": 214.28840970350404
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 593,
          "y": 211.28436657681942
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 594,
          "y": 208.28032345013477
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 594,
          "y": 206.277628032345
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 594,
          "y": 204.27493261455527
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 595,
          "y": 202.2722371967655
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 595,
          "y": 199.26819407008088
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 595,
          "y": 196.26415094339623
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 596,
          "y": 192.25876010781673
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 597,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 597,
          "y": 186.25067385444746
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 598,
          "y": 184.2479784366577
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 599,
          "y": 182.24528301886792
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 599,
          "y": 179.2412398921833
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 600,
          "y": 177.23854447439354
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 600,
          "y": 176.23719676549865
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 601,
          "y": 175.23584905660377
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 601,
          "y": 174.23450134770889
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 601,
          "y": 173.23315363881403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 601,
          "y": 173.23315363881403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 601,
          "y": 173.23315363881403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 602,
          "y": 173.23315363881403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 602,
          "y": 173.23315363881403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 602,
          "y": 173.23315363881403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 602,
          "y": 174.23450134770889
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 603,
          "y": 174.23450134770889
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 604,
          "y": 176.23719676549865
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 605,
          "y": 177.23854447439354
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 607,
          "y": 179.2412398921833
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 611,
          "y": 182.24528301886792
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 616,
          "y": 187.2520215633423
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 621,
          "y": 192.25876010781673
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 627,
          "y": 198.266846361186
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 635,
          "y": 205.27628032345015
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 642,
          "y": 213.28706199460916
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 649,
          "y": 222.29919137466308
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 654,
          "y": 228.30727762803235
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 665,
          "y": 242.32614555256066
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 670,
          "y": 250.33692722371967
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 675,
          "y": 257.3463611859838
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 679,
          "y": 262.3530997304582
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 687,
          "y": 274.36927223719675
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 691,
          "y": 280.37735849056605
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 695,
          "y": 286.3854447439353
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 698,
          "y": 290.3908355795148
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 700,
          "y": 293.39487870619945
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 702,
          "y": 296.3989218328841
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 704,
          "y": 299.40296495956875
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 705,
          "y": 300.4043126684636
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 706,
          "y": 302.40700808625337
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 707,
          "y": 303.4083557951483
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 707,
          "y": 304.40970350404314
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 708,
          "y": 305.411051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 709,
          "y": 306.4123989218329
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 710,
          "y": 307.41374663072776
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 710,
          "y": 308.41509433962267
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 711,
          "y": 309.4164420485175
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 712,
          "y": 310.4177897574124
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 712,
          "y": 311.4191374663073
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 713,
          "y": 312.42048517520215
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 713,
          "y": 312.42048517520215
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 713,
          "y": 313.42183288409706
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 714,
          "y": 313.42183288409706
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 606,
          "y": 261.35175202156336
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 606,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 607,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 608,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 610,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 614,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 620,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 629,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 635,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 640,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 644,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 647,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 650,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 653,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 655,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 657,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 659,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 661,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 662,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 664,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 667,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 669,
          "y": 259.3490566037736
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 673,
          "y": 258.34770889487874
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 674,
          "y": 258.34770889487874
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 675,
          "y": 258.34770889487874
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 676,
          "y": 257.3463611859838
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 677,
          "y": 257.3463611859838
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 677,
          "y": 257.3463611859838
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 677,
          "y": 257.3463611859838
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 164.2210242587601
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 164.2210242587601
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 164.2210242587601
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 165.222371967655
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 173.23315363881403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 177.23854447439354
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 184.2479784366577
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 192.25876010781673
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 200.26954177897574
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 211.28436657681942
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 223.30053908355796
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 234.31536388140162
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 254.3423180592992
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 260.35040431266845
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 267.3598382749326
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 275.37061994609167
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 282.3800539083558
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 287.3867924528302
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 293.39487870619945
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 299.40296495956875
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 303.4083557951483
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 307.41374663072776
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 311.4191374663073
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 315.4245283018868
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 320.4312668463612
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 324.4366576819407
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 328.4420485175202
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 331.44609164420484
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 334.4501347708895
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 336.45283018867923
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 338.455525606469
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 341.4595687331537
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 342.46091644204853
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 342.46091644204853
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 343.4622641509434
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 344.4636118598383
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 345.46495956873315
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 346.46630727762806
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 347.4676549865229
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 348.46900269541777
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 349.4703504043127
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 349.4703504043127
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 350.47169811320754
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 751,
          "y": 350.47169811320754
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 761,
          "y": 167.22506738544476
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 762,
          "y": 168.22641509433961
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 763,
          "y": 170.22911051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 764,
          "y": 171.23045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 765,
          "y": 173.23315363881403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 767,
          "y": 175.23584905660377
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 769,
          "y": 177.23854447439354
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 771,
          "y": 180.24258760107818
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 774,
          "y": 183.2466307277628
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 778,
          "y": 186.25067385444746
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 780,
          "y": 189.25471698113208
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 783,
          "y": 191.25741239892184
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 785,
          "y": 193.26010781671158
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 787,
          "y": 195.26280323450135
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 788,
          "y": 196.26415094339623
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 789,
          "y": 197.26549865229111
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 790,
          "y": 198.266846361186
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 791,
          "y": 198.266846361186
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 791,
          "y": 198.266846361186
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 791,
          "y": 199.26819407008088
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 792,
          "y": 199.26819407008088
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 792,
          "y": 199.26819407008088
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 792,
          "y": 200.26954177897574
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 793,
          "y": 200.26954177897574
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 793,
          "y": 200.26954177897574
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 794,
          "y": 201.27088948787062
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 794,
          "y": 202.2722371967655
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 794,
          "y": 203.27358490566039
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 795,
          "y": 205.27628032345015
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 795,
          "y": 207.2789757412399
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 796,
          "y": 210.28301886792454
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 796,
          "y": 212.28571428571428
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 797,
          "y": 214.28840970350404
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 797,
          "y": 217.2924528301887
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 798,
          "y": 219.29514824797843
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 798,
          "y": 220.29649595687331
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 798,
          "y": 221.2978436657682
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 799,
          "y": 222.29919137466308
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 799,
          "y": 222.29919137466308
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 799,
          "y": 223.30053908355796
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 799,
          "y": 223.30053908355796
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 799,
          "y": 223.30053908355796
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 799,
          "y": 224.30188679245285
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 800,
          "y": 224.30188679245285
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 800,
          "y": 225.3032345013477
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 800,
          "y": 225.3032345013477
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 800,
          "y": 225.3032345013477
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 800,
          "y": 225.3032345013477
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 800,
          "y": 226.3045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 800,
          "y": 226.3045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 800,
          "y": 226.3045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 800,
          "y": 226.3045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 801,
          "y": 227.30592991913747
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 801,
          "y": 227.30592991913747
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 801,
          "y": 227.30592991913747
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 801,
          "y": 227.30592991913747
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 802,
          "y": 227.30592991913747
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 802,
          "y": 227.30592991913747
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 804,
          "y": 226.3045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 805,
          "y": 225.3032345013477
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 807,
          "y": 223.30053908355796
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 809,
          "y": 221.2978436657682
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 811,
          "y": 219.29514824797843
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 816,
          "y": 213.28706199460916
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 818,
          "y": 210.28301886792454
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 820,
          "y": 206.277628032345
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 823,
          "y": 202.2722371967655
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 826,
          "y": 198.266846361186
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 829,
          "y": 194.26145552560646
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 831,
          "y": 190.25606469002696
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 834,
          "y": 186.25067385444746
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 837,
          "y": 182.24528301886792
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 840,
          "y": 179.2412398921833
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 842,
          "y": 176.23719676549865
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 845,
          "y": 173.23315363881403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 847,
          "y": 171.23045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 849,
          "y": 169.2277628032345
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 851,
          "y": 167.22506738544476
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 852,
          "y": 166.22371967654988
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 853,
          "y": 165.222371967655
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 854,
          "y": 164.2210242587601
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 855,
          "y": 164.2210242587601
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 855,
          "y": 163.21967654986523
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 855,
          "y": 163.21967654986523
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 163.21967654986523
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 164.2210242587601
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 166.22371967654988
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 168.22641509433961
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 172.23180592991915
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 176.23719676549865
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 181.24393530997304
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 186.25067385444746
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 192.25876010781673
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 199.26819407008088
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 205.27628032345015
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 212.28571428571428
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 216.2911051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 856,
          "y": 221.2978436657682
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 857,
          "y": 227.30592991913747
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 858,
          "y": 235.3167115902965
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 858,
          "y": 239.322102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 859,
          "y": 246.33153638814017
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 859,
          "y": 252.33962264150944
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 860,
          "y": 258.34770889487874
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 860,
          "y": 265.3571428571429
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 861,
          "y": 272.366576819407
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 861,
          "y": 278.3746630727763
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 289.38948787062
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 297.400269541779
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 305.411051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 309.4164420485175
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 312.42048517520215
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 315.4245283018868
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 318.42857142857144
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 321.43261455525607
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 323.43530997304583
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 326.43935309973045
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 329.4433962264151
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 331.44609164420484
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 332.44743935309975
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 334.4501347708895
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 336.45283018867923
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 337.45417789757414
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 338.455525606469
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 339.4568733153639
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 339.4568733153639
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 339.4568733153639
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 862,
          "y": 340.45822102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 913,
          "y": 327.44070080862537
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 913,
          "y": 327.44070080862537
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 913,
          "y": 327.44070080862537
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 913,
          "y": 325.4380053908356
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 913,
          "y": 319.4299191374663
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 913,
          "y": 308.41509433962267
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 915,
          "y": 295.3975741239892
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 916,
          "y": 288.38814016172506
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 917,
          "y": 281.3787061994609
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 919,
          "y": 274.36927223719675
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 921,
          "y": 267.3598382749326
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 923,
          "y": 260.35040431266845
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 925,
          "y": 252.33962264150944
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 927,
          "y": 245.33018867924528
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 929,
          "y": 239.322102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 930,
          "y": 234.31536388140162
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 932,
          "y": 227.30592991913747
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 933,
          "y": 223.30053908355796
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 934,
          "y": 218.29380053908358
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 936,
          "y": 213.28706199460916
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 936,
          "y": 211.28436657681942
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 937,
          "y": 208.28032345013477
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 938,
          "y": 206.277628032345
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 938,
          "y": 204.27493261455527
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 939,
          "y": 202.2722371967655
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 939,
          "y": 201.27088948787062
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 940,
          "y": 200.26954177897574
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 940,
          "y": 199.26819407008088
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 940,
          "y": 198.266846361186
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 940,
          "y": 197.26549865229111
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 941,
          "y": 196.26415094339623
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 941,
          "y": 195.26280323450135
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 942,
          "y": 193.26010781671158
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 943,
          "y": 191.25741239892184
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 944,
          "y": 188.2533692722372
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 945,
          "y": 186.25067385444746
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 946,
          "y": 184.2479784366577
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 947,
          "y": 182.24528301886792
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 949,
          "y": 179.2412398921833
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 950,
          "y": 177.23854447439354
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 951,
          "y": 175.23584905660377
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 951,
          "y": 174.23450134770889
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 951,
          "y": 173.23315363881403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 952,
          "y": 172.23180592991915
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 952,
          "y": 171.23045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 952,
          "y": 171.23045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 952,
          "y": 170.22911051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 952,
          "y": 170.22911051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 953,
          "y": 170.22911051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 953,
          "y": 170.22911051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 953,
          "y": 170.22911051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 953,
          "y": 170.22911051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 954,
          "y": 170.22911051212938
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 954,
          "y": 171.23045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 954,
          "y": 171.23045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 954,
          "y": 172.23180592991915
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 954,
          "y": 172.23180592991915
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 954,
          "y": 173.23315363881403
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 954,
          "y": 174.23450134770889
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 955,
          "y": 175.23584905660377
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 955,
          "y": 176.23719676549865
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 957,
          "y": 179.2412398921833
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 959,
          "y": 182.24528301886792
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 963,
          "y": 187.2520215633423
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 967,
          "y": 192.25876010781673
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 971,
          "y": 197.26549865229111
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 975,
          "y": 202.2722371967655
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 980,
          "y": 209.28167115902966
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 985,
          "y": 214.28840970350404
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 990,
          "y": 220.29649595687331
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 994,
          "y": 226.3045822102426
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 998,
          "y": 231.31132075471697
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1005,
          "y": 239.322102425876
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1009,
          "y": 244.3288409703504
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1013,
          "y": 250.33692722371967
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1019,
          "y": 256.34501347708897
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1024,
          "y": 263.3544474393531
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1029,
          "y": 269.36253369272237
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1034,
          "y": 275.37061994609167
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1038,
          "y": 281.3787061994609
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1042,
          "y": 285.38409703504044
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1044,
          "y": 288.38814016172506
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1047,
          "y": 292.3935309973046
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1049,
          "y": 294.39622641509436
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1051,
          "y": 296.3989218328841
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1052,
          "y": 298.40161725067384
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1053,
          "y": 299.40296495956875
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1053,
          "y": 300.4043126684636
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1054,
          "y": 300.4043126684636
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1054,
          "y": 300.4043126684636
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1054,
          "y": 301.4056603773585
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1054,
          "y": 301.4056603773585
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1055,
          "y": 301.4056603773585
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1055,
          "y": 301.4056603773585
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1054,
          "y": 300.4043126684636
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 927,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 929,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 933,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 939,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 948,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 958,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 970,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 981,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 990,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 997,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1003,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1007,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1012,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1016,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1018,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1020,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1021,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1021,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1022,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1022,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1022,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1023,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1023,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1024,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1024,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1024,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1024,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1025,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "pine",
      "point": {
          "x": 1025,
          "y": 243.32749326145554
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 425.57277628032347
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 427.57547169811323
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 430.57951482479785
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 432.5822102425876
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 435.58625336927224
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 442.5956873315364
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 444.59838274932616
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 449.60512129380055
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 454.61185983827494
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 460.61994609164424
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 467.6293800539084
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 474.6388140161725
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 480.6469002695418
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 487.65633423180594
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 493.6644204851752
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 499.6725067385445
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 505.6805929919138
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 511.688679245283
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 415,
          "y": 518.6981132075472
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 416,
          "y": 524.7061994609164
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 530.7142857142858
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 534.7196765498652
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 539.7264150943396
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 544.733153638814
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 549.7398921832885
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 553.7452830188679
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 558.7520215633423
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 562.7574123989218
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 566.7628032345013
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 570.7681940700809
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 575.7749326145553
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 583.7857142857143
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 590.7951482479784
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 596.8032345013477
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 603.8126684636119
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 608.8194070080863
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 612.8247978436658
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 617.8315363881402
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 620.8355795148248
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 624.8409703504043
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 626.8436657681941
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 627.845013477089
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 629.8477088948787
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 631.8504043126685
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 631.8504043126685
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 632.8517520215634
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 632.8517520215634
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 633.8530997304582
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 634.8544474393531
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 635.855795148248
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 635.855795148248
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 636.8571428571429
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 637.8584905660377
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 638.8598382749326
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 417,
          "y": 638.8598382749326
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 425,
          "y": 429.578167115903
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 425,
          "y": 429.578167115903
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 425,
          "y": 429.578167115903
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 425,
          "y": 430.57951482479785
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 425,
          "y": 432.5822102425876
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 425,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 427,
          "y": 437.588948787062
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 428,
          "y": 440.59299191374663
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 430,
          "y": 444.59838274932616
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 432,
          "y": 448.6037735849057
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 434,
          "y": 452.6091644204852
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 436,
          "y": 456.6145552560647
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 438,
          "y": 460.61994609164424
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 440,
          "y": 463.62398921832886
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 441,
          "y": 466.6280323450135
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 443,
          "y": 470.633423180593
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 444,
          "y": 473.63746630727763
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 445,
          "y": 476.64150943396226
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 447,
          "y": 478.644204851752
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 448,
          "y": 481.64824797843664
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 450,
          "y": 484.6522911051213
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 451,
          "y": 487.65633423180594
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 452,
          "y": 489.6590296495957
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 453,
          "y": 492.66307277628033
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 454,
          "y": 494.6657681940701
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 456,
          "y": 496.66846361185986
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 457,
          "y": 498.67115902964963
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 458,
          "y": 500.67385444743934
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 459,
          "y": 502.6765498652291
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 460,
          "y": 504.6792452830189
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 460,
          "y": 505.6805929919138
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 461,
          "y": 507.6832884097035
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 462,
          "y": 508.6846361185984
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 462,
          "y": 508.6846361185984
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 463,
          "y": 509.68598382749326
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 463,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 463,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 464,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 464,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 464,
          "y": 511.688679245283
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 464,
          "y": 511.688679245283
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 464,
          "y": 511.688679245283
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 465,
          "y": 511.688679245283
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 465,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 465,
          "y": 509.68598382749326
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 466,
          "y": 508.6846361185984
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 467,
          "y": 505.6805929919138
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 469,
          "y": 502.6765498652291
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 471,
          "y": 498.67115902964963
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 474,
          "y": 493.6644204851752
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 478,
          "y": 486.6549865229111
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 483,
          "y": 479.64555256064693
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 486,
          "y": 473.63746630727763
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 491,
          "y": 466.6280323450135
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 495,
          "y": 460.61994609164424
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 499,
          "y": 453.6105121293801
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 509,
          "y": 437.588948787062
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 511,
          "y": 435.58625336927224
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 513,
          "y": 432.5822102425876
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 514,
          "y": 429.578167115903
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 516,
          "y": 427.57547169811323
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 517,
          "y": 425.57277628032347
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 518,
          "y": 422.56873315363885
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 518,
          "y": 421.56738544474393
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 519,
          "y": 420.5660377358491
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 519,
          "y": 420.5660377358491
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 519,
          "y": 419.56469002695417
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 520,
          "y": 419.56469002695417
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 520,
          "y": 419.56469002695417
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 520,
          "y": 419.56469002695417
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 520,
          "y": 419.56469002695417
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 520,
          "y": 420.5660377358491
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 521,
          "y": 421.56738544474393
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 521,
          "y": 423.5700808625337
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 521,
          "y": 425.57277628032347
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 521,
          "y": 427.57547169811323
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 522,
          "y": 429.578167115903
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 522,
          "y": 433.5835579514825
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 523,
          "y": 439.5916442048518
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 524,
          "y": 447.6024258760108
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 526,
          "y": 456.6145552560647
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 527,
          "y": 466.6280323450135
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 529,
          "y": 477.64285714285717
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 531,
          "y": 489.6590296495957
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 532,
          "y": 499.6725067385445
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 534,
          "y": 508.6846361185984
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 536,
          "y": 518.6981132075472
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 537,
          "y": 528.711590296496
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 538,
          "y": 536.722371967655
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 539,
          "y": 543.7318059299191
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 541,
          "y": 550.7412398921833
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 542,
          "y": 557.7506738544474
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 543,
          "y": 563.7587601078167
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 543,
          "y": 569.766846361186
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 544,
          "y": 575.7749326145553
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 545,
          "y": 581.7830188679245
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 546,
          "y": 589.7938005390836
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 546,
          "y": 591.7964959568733
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 547,
          "y": 594.800539083558
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 548,
          "y": 597.8045822102426
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 548,
          "y": 599.8072776280324
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 548,
          "y": 601.8099730458221
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 603.8126684636119
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 604.8140161725067
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 604.8140161725067
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 605.8153638814016
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 605.8153638814016
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 605.8153638814016
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 606.8167115902966
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 606.8167115902966
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 606.8167115902966
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 606.8167115902966
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 607.8180592991914
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 607.8180592991914
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 609.8207547169811
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 611.823450134771
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 549,
          "y": 613.8261455525607
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 589,
          "y": 594.800539083558
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 589,
          "y": 593.7991913746631
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 589,
          "y": 593.7991913746631
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 589,
          "y": 591.7964959568733
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 589,
          "y": 588.7924528301887
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 589,
          "y": 584.7870619946092
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 589,
          "y": 578.77897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 589,
          "y": 572.7708894878706
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 589,
          "y": 565.7614555256065
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 589,
          "y": 560.7547169811321
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 591,
          "y": 553.7452830188679
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 592,
          "y": 546.7358490566038
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 594,
          "y": 538.7250673854447
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 595,
          "y": 530.7142857142858
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 595,
          "y": 526.7088948787062
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 596,
          "y": 520.7008086253369
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 597,
          "y": 514.6927223719676
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 597,
          "y": 509.68598382749326
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 597,
          "y": 503.677897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 598,
          "y": 497.6698113207547
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 598,
          "y": 491.6617250673855
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 599,
          "y": 486.6549865229111
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 599,
          "y": 482.64959568733155
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 599,
          "y": 476.64150943396226
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 600,
          "y": 471.63477088948787
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 600,
          "y": 468.63072776280325
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 600,
          "y": 465.6266846361186
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 600,
          "y": 461.6212938005391
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 601,
          "y": 459.6185983827493
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 601,
          "y": 456.6145552560647
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 601,
          "y": 455.61320754716985
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 602,
          "y": 453.6105121293801
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 602,
          "y": 452.6091644204852
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 602,
          "y": 451.6078167115903
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 603,
          "y": 450.6064690026954
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 603,
          "y": 449.60512129380055
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 604,
          "y": 447.6024258760108
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 604,
          "y": 445.599730458221
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 604,
          "y": 444.59838274932616
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 605,
          "y": 442.5956873315364
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 605,
          "y": 440.59299191374663
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 606,
          "y": 436.58760107816715
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 606,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 607,
          "y": 433.5835579514825
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 607,
          "y": 431.5808625336927
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 607,
          "y": 430.57951482479785
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 608,
          "y": 428.5768194070081
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 608,
          "y": 427.57547169811323
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 608,
          "y": 427.57547169811323
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 608,
          "y": 427.57547169811323
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 608,
          "y": 426.5741239892183
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 608,
          "y": 426.5741239892183
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 608,
          "y": 426.5741239892183
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 608,
          "y": 426.5741239892183
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 609,
          "y": 427.57547169811323
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 609,
          "y": 428.5768194070081
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 609,
          "y": 429.578167115903
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 609,
          "y": 431.5808625336927
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 610,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 611,
          "y": 436.58760107816715
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 613,
          "y": 438.59029649595686
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 614,
          "y": 441.59433962264154
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 617,
          "y": 446.60107816711593
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 620,
          "y": 450.6064690026954
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 624,
          "y": 457.61590296495956
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 629,
          "y": 465.6266846361186
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 633,
          "y": 473.63746630727763
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 636,
          "y": 479.64555256064693
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 640,
          "y": 486.6549865229111
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 644,
          "y": 494.6657681940701
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 650,
          "y": 508.6846361185984
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 653,
          "y": 514.6927223719676
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 655,
          "y": 520.7008086253369
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 658,
          "y": 525.7075471698113
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 659,
          "y": 530.7142857142858
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 661,
          "y": 533.7183288409703
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 662,
          "y": 536.722371967655
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 663,
          "y": 539.7264150943396
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 665,
          "y": 541.7291105121294
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 667,
          "y": 546.7358490566038
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 668,
          "y": 549.7398921832885
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 669,
          "y": 551.7425876010782
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 671,
          "y": 554.7466307277629
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 672,
          "y": 557.7506738544474
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 673,
          "y": 559.7533692722373
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 675,
          "y": 562.7574123989218
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 676,
          "y": 564.7601078167116
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 677,
          "y": 566.7628032345013
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 678,
          "y": 568.7654986522912
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 679,
          "y": 570.7681940700809
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 679,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 680,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 680,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 680,
          "y": 572.7708894878706
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 609,
          "y": 515.6940700808625
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 611,
          "y": 515.6940700808625
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 614,
          "y": 515.6940700808625
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 618,
          "y": 515.6940700808625
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 624,
          "y": 514.6927223719676
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 630,
          "y": 513.6913746630728
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 637,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 642,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 647,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 653,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 658,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 662,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 665,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 667,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 669,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 671,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 673,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 675,
          "y": 511.688679245283
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 677,
          "y": 511.688679245283
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 679,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 680,
          "y": 509.68598382749326
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 682,
          "y": 509.68598382749326
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 683,
          "y": 508.6846361185984
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 685,
          "y": 507.6832884097035
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 686,
          "y": 506.68194070080864
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 687,
          "y": 506.68194070080864
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 687,
          "y": 506.68194070080864
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 687,
          "y": 505.6805929919138
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 688,
          "y": 505.6805929919138
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 407.5485175202156
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 407.5485175202156
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 408.54986522911054
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 409.5512129380054
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 411.55390835579516
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 415.5592991913747
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 420.5660377358491
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 426.5741239892183
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 431.5808625336927
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 439.5916442048518
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 447.6024258760108
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 456.6145552560647
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 465.6266846361186
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 472.6361185983828
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 478.644204851752
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 485.6536388140162
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 492.66307277628033
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 499.6725067385445
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 505.6805929919138
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 514.6927223719676
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 522.7035040431267
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 527.7102425876011
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 538.7250673854447
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 543.7318059299191
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 549.7398921832885
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 553.7452830188679
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 558.7520215633423
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 562.7574123989218
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 722,
          "y": 565.7614555256065
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 723,
          "y": 570.7681940700809
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 723,
          "y": 572.7708894878706
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 723,
          "y": 574.7735849056604
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 723,
          "y": 577.777628032345
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 723,
          "y": 578.77897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 723,
          "y": 580.7816711590297
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 723,
          "y": 581.7830188679245
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 724,
          "y": 582.7843665768194
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 724,
          "y": 584.7870619946092
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 724,
          "y": 585.788409703504
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 724,
          "y": 586.7897574123989
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 724,
          "y": 588.7924528301887
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 724,
          "y": 589.7938005390836
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 724,
          "y": 590.7951482479784
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 724,
          "y": 591.7964959568733
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 724,
          "y": 591.7964959568733
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 725,
          "y": 592.7978436657683
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 725,
          "y": 592.7978436657683
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 725,
          "y": 593.7991913746631
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 725,
          "y": 593.7991913746631
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 705,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 705,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 711,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 713,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 715,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 719,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 724,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 729,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 734,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 739,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 743,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 745,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 747,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 749,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 751,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 754,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 755,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 756,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 758,
          "y": 435.58625336927224
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 761,
          "y": 436.58760107816715
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 762,
          "y": 437.588948787062
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 764,
          "y": 437.588948787062
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 765,
          "y": 438.59029649595686
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 769,
          "y": 440.59299191374663
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 770,
          "y": 441.59433962264154
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 772,
          "y": 442.5956873315364
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 774,
          "y": 443.59703504043125
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 776,
          "y": 444.59838274932616
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 777,
          "y": 445.599730458221
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 779,
          "y": 447.6024258760108
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 780,
          "y": 448.6037735849057
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 781,
          "y": 449.60512129380055
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 781,
          "y": 449.60512129380055
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 782,
          "y": 450.6064690026954
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 783,
          "y": 451.6078167115903
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 783,
          "y": 452.6091644204852
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 783,
          "y": 452.6091644204852
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 783,
          "y": 453.6105121293801
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 783,
          "y": 454.61185983827494
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 783,
          "y": 455.61320754716985
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 783,
          "y": 456.6145552560647
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 783,
          "y": 459.6185983827493
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 782,
          "y": 462.62264150943395
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 780,
          "y": 465.6266846361186
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 776,
          "y": 473.63746630727763
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 770,
          "y": 481.64824797843664
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 763,
          "y": 490.66037735849056
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 757,
          "y": 496.66846361185986
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 753,
          "y": 499.6725067385445
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 749,
          "y": 502.6765498652291
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 746,
          "y": 504.6792452830189
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 744,
          "y": 505.6805929919138
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 742,
          "y": 506.68194070080864
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 740,
          "y": 507.6832884097035
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 737,
          "y": 508.6846361185984
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 735,
          "y": 509.68598382749326
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 731,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 728,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 727,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 726,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 725,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 725,
          "y": 513.6913746630728
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 725,
          "y": 513.6913746630728
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 724,
          "y": 513.6913746630728
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 725,
          "y": 513.6913746630728
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 725,
          "y": 513.6913746630728
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 726,
          "y": 513.6913746630728
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 727,
          "y": 514.6927223719676
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 728,
          "y": 514.6927223719676
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 730,
          "y": 515.6940700808625
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 732,
          "y": 516.6954177897575
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 735,
          "y": 518.6981132075472
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 740,
          "y": 521.7021563342319
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 746,
          "y": 525.7075471698113
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 752,
          "y": 529.7129380053908
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 759,
          "y": 534.7196765498652
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 769,
          "y": 540.7277628032346
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 774,
          "y": 544.733153638814
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 781,
          "y": 548.7385444743935
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 786,
          "y": 551.7425876010782
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 791,
          "y": 554.7466307277629
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 796,
          "y": 557.7506738544474
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 799,
          "y": 559.7533692722373
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 803,
          "y": 561.756064690027
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 806,
          "y": 563.7587601078167
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 808,
          "y": 565.7614555256065
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 810,
          "y": 566.7628032345013
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 812,
          "y": 567.7641509433962
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 814,
          "y": 568.7654986522912
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 816,
          "y": 570.7681940700809
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 818,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 819,
          "y": 572.7708894878706
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 820,
          "y": 573.7722371967656
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 821,
          "y": 573.7722371967656
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 822,
          "y": 574.7735849056604
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 822,
          "y": 574.7735849056604
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 822,
          "y": 574.7735849056604
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 823,
          "y": 575.7749326145553
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 823,
          "y": 575.7749326145553
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 823,
          "y": 575.7749326145553
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 823,
          "y": 575.7749326145553
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 824,
          "y": 575.7749326145553
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 842,
          "y": 422.56873315363885
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 842,
          "y": 422.56873315363885
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 842,
          "y": 424.57142857142856
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 842,
          "y": 427.57547169811323
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 842,
          "y": 432.5822102425876
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 842,
          "y": 438.59029649595686
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 842,
          "y": 446.60107816711593
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 842,
          "y": 452.6091644204852
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 842,
          "y": 467.6293800539084
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 842,
          "y": 477.64285714285717
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 842,
          "y": 487.65633423180594
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 842,
          "y": 500.67385444743934
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 843,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 843,
          "y": 522.7035040431267
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 844,
          "y": 531.7156334231806
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 844,
          "y": 537.7237196765499
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 844,
          "y": 544.733153638814
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 845,
          "y": 551.7425876010782
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 846,
          "y": 558.7520215633423
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 846,
          "y": 564.7601078167116
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 847,
          "y": 569.766846361186
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 847,
          "y": 573.7722371967656
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 847,
          "y": 575.7749326145553
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 848,
          "y": 578.77897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 848,
          "y": 581.7830188679245
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 849,
          "y": 583.7857142857143
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 849,
          "y": 585.788409703504
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 850,
          "y": 587.7911051212939
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 850,
          "y": 587.7911051212939
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 850,
          "y": 588.7924528301887
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 851,
          "y": 588.7924528301887
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 851,
          "y": 589.7938005390836
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 851,
          "y": 589.7938005390836
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 851,
          "y": 589.7938005390836
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 851,
          "y": 589.7938005390836
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 851,
          "y": 590.7951482479784
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 851,
          "y": 590.7951482479784
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 851,
          "y": 590.7951482479784
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 922,
          "y": 419.56469002695417
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 922,
          "y": 420.5660377358491
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 920,
          "y": 422.56873315363885
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 918,
          "y": 426.5741239892183
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 915,
          "y": 430.57951482479785
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 911,
          "y": 434.5849056603774
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 907,
          "y": 439.5916442048518
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 903,
          "y": 444.59838274932616
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 899,
          "y": 450.6064690026954
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 896,
          "y": 454.61185983827494
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 890,
          "y": 462.62264150943395
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 888,
          "y": 464.6253369272237
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 886,
          "y": 467.6293800539084
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 884,
          "y": 469.6320754716981
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 883,
          "y": 471.63477088948787
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 882,
          "y": 472.6361185983828
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 881,
          "y": 473.63746630727763
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 880,
          "y": 473.63746630727763
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 880,
          "y": 474.6388140161725
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 880,
          "y": 474.6388140161725
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 880,
          "y": 474.6388140161725
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 880,
          "y": 475.6401617250674
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 880,
          "y": 475.6401617250674
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 879,
          "y": 475.6401617250674
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 879,
          "y": 476.64150943396226
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 876,
          "y": 478.644204851752
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 875,
          "y": 479.64555256064693
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 873,
          "y": 482.64959568733155
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 870,
          "y": 485.6536388140162
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 867,
          "y": 489.6590296495957
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 864,
          "y": 492.66307277628033
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 861,
          "y": 496.66846361185986
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 858,
          "y": 499.6725067385445
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 856,
          "y": 502.6765498652291
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 854,
          "y": 505.6805929919138
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 852,
          "y": 507.6832884097035
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 851,
          "y": 508.6846361185984
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 850,
          "y": 509.68598382749326
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 850,
          "y": 509.68598382749326
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 850,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 850,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 850,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 850,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 850,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 851,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 851,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 852,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 853,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 854,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 856,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 858,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 861,
          "y": 512.6900269541779
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 865,
          "y": 513.6913746630728
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 874,
          "y": 517.6967654986523
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 886,
          "y": 524.7061994609164
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 898,
          "y": 530.7142857142858
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 904,
          "y": 534.7196765498652
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 911,
          "y": 538.7250673854447
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 918,
          "y": 542.7304582210243
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 925,
          "y": 546.7358490566038
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 931,
          "y": 549.7398921832885
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 935,
          "y": 552.743935309973
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 942,
          "y": 557.7506738544474
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 944,
          "y": 558.7520215633423
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 947,
          "y": 560.7547169811321
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 949,
          "y": 561.756064690027
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 950,
          "y": 562.7574123989218
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 951,
          "y": 563.7587601078167
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 952,
          "y": 564.7601078167116
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 954,
          "y": 565.7614555256065
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 955,
          "y": 566.7628032345013
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 956,
          "y": 567.7641509433962
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 957,
          "y": 568.7654986522912
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 958,
          "y": 569.766846361186
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 959,
          "y": 570.7681940700809
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 960,
          "y": 570.7681940700809
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 961,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 961,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 962,
          "y": 572.7708894878706
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 962,
          "y": 572.7708894878706
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 986,
          "y": 430.57951482479785
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 986,
          "y": 433.5835579514825
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 986,
          "y": 438.59029649595686
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 987,
          "y": 448.6037735849057
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 988,
          "y": 460.61994609164424
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 990,
          "y": 474.6388140161725
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 993,
          "y": 488.6576819407008
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 995,
          "y": 499.6725067385445
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 998,
          "y": 513.6913746630728
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1001,
          "y": 526.7088948787062
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1003,
          "y": 534.7196765498652
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1007,
          "y": 547.7371967654987
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1008,
          "y": 552.743935309973
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1009,
          "y": 557.7506738544474
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1011,
          "y": 563.7587601078167
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1011,
          "y": 566.7628032345013
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1012,
          "y": 568.7654986522912
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1012,
          "y": 568.7654986522912
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1012,
          "y": 569.766846361186
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1012,
          "y": 569.766846361186
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1012,
          "y": 570.7681940700809
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1013,
          "y": 570.7681940700809
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1013,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1013,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1013,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1013,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1013,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1014,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1014,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1014,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1015,
          "y": 570.7681940700809
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1016,
          "y": 569.766846361186
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1017,
          "y": 568.7654986522912
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1021,
          "y": 567.7641509433962
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1024,
          "y": 565.7614555256065
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1029,
          "y": 563.7587601078167
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1035,
          "y": 561.756064690027
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1042,
          "y": 559.7533692722373
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1048,
          "y": 557.7506738544474
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1054,
          "y": 555.7479784366577
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1061,
          "y": 553.7452830188679
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1068,
          "y": 551.7425876010782
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1074,
          "y": 549.7398921832885
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1079,
          "y": 547.7371967654987
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1083,
          "y": 546.7358490566038
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1088,
          "y": 545.7345013477089
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1091,
          "y": 544.733153638814
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1095,
          "y": 543.7318059299191
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1097,
          "y": 542.7304582210243
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1099,
          "y": 541.7291105121294
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1101,
          "y": 540.7277628032346
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1103,
          "y": 540.7277628032346
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1104,
          "y": 539.7264150943396
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1105,
          "y": 539.7264150943396
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1106,
          "y": 539.7264150943396
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1106,
          "y": 538.7250673854447
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1107,
          "y": 538.7250673854447
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1107,
          "y": 538.7250673854447
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1107,
          "y": 538.7250673854447
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1002,
          "y": 488.6576819407008
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1002,
          "y": 488.6576819407008
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1002,
          "y": 488.6576819407008
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1003,
          "y": 488.6576819407008
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1005,
          "y": 487.65633423180594
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1010,
          "y": 486.6549865229111
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1015,
          "y": 485.6536388140162
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1031,
          "y": 480.6469002695418
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1049,
          "y": 475.6401617250674
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1055,
          "y": 473.63746630727763
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1061,
          "y": 472.6361185983828
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1066,
          "y": 471.63477088948787
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1070,
          "y": 471.63477088948787
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1073,
          "y": 470.633423180593
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1076,
          "y": 470.633423180593
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1078,
          "y": 470.633423180593
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1079,
          "y": 470.633423180593
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1079,
          "y": 470.633423180593
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1079,
          "y": 470.633423180593
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1080,
          "y": 469.6320754716981
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 993,
          "y": 417.56199460916446
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 993,
          "y": 417.56199460916446
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 993,
          "y": 417.56199460916446
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 996,
          "y": 417.56199460916446
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1000,
          "y": 416.56064690026955
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1008,
          "y": 414.5579514824798
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1015,
          "y": 412.55525606469
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1023,
          "y": 409.5512129380054
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1034,
          "y": 406.54716981132077
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1042,
          "y": 403.54312668463615
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1050,
          "y": 401.5404312668464
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1057,
          "y": 399.5377358490566
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1064,
          "y": 398.53638814016176
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1069,
          "y": 397.53504043126685
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1071,
          "y": 397.53504043126685
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1075,
          "y": 397.53504043126685
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1078,
          "y": 397.53504043126685
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1079,
          "y": 397.53504043126685
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1080,
          "y": 397.53504043126685
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1081,
          "y": 397.53504043126685
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1082,
          "y": 397.53504043126685
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1082,
          "y": 397.53504043126685
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1144,
          "y": 403.54312668463615
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1144,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1146,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1150,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1156,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1165,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1176,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1189,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1202,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1215,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1223,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1230,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1238,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1244,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1250,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1257,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1263,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1268,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1271,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1274,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1277,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1280,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1283,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1285,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1287,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1288,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1289,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1289,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1290,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1290,
          "y": 402.54177897574124
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 396.533692722372
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 397.53504043126685
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 403.54312668463615
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 426.5741239892183
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 451.6078167115903
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 463.62398921832886
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 480.6469002695418
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 495.66711590296495
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 510.68733153638817
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 525.7075471698113
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 543.7318059299191
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 551.7425876010782
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 559.7533692722373
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 566.7628032345013
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 571.7695417789757
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 576.7762803234501
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 581.7830188679245
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 583.7857142857143
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 586.7897574123989
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 588.7924528301887
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 590.7951482479784
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 591.7964959568733
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 592.7978436657683
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 593.7991913746631
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 594.800539083558
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 595.8018867924528
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 596.8032345013477
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 597.8045822102426
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 598.8059299191375
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 599.8072776280324
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 600.8086253369272
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 601.8099730458221
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 602.811320754717
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 603.8126684636119
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1214,
          "y": 604.8140161725067
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1215,
          "y": 604.8140161725067
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1215,
          "y": 605.8153638814016
      }
  },
  {
      "brushType": "flower",
      "point": {
          "x": 1215,
          "y": 605.8153638814016
      }
  }
]
